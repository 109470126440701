import React, { Component } from "react";
import Header from "../../components/header/header";
import FloatToast from "../../components/float-toast/float-toast";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop/scrollTop";
import  partnerHeaderImg  from "../../static/images/product/partner_header_img.png";
import  partnerHeaderWxIcon  from "../../static/images/product/partner_header_wx_icon.png";
import  partnerHeaderItemIcon from "../../static/images/product/partner_header_item_icon.png";
import  partnerExpertImg  from "../../static/images/product/partner_expert_img.png";
import  partnerContentImg  from "../../static/images/product/partner_content_img.png";
import  partnerFlowImg  from "../../static/images/product/partner_flow_img.png";

import "./partner-settlement.css";
export default class PartnerSettlement extends Component {
    constructor(props){
        super(props);
        this.state = {
          headerList:['一个综合性的美业服务平台','提升用户体验、优化门店管理、增强营销效果','专为美容、美发、美甲等美业行业设计'],
          elementTop:0
        }
    }
    handleClick(){
      let style2;
      let selectorSystem = document.querySelector(".partner_expert_img_top");
      if(selectorSystem){
        style2 = window.getComputedStyle(selectorSystem)
      }
      let top = (parseInt(style2?.getPropertyValue("height"))+10)
      window.scrollTo({
        top,
        behavior: "smooth"
      });
    }
    render() {
        return (
          <div className="partner-settlement">
            <Header index={3} history={this.props.history}></Header>
            <FloatToast></FloatToast>
            <div className="partner_expert_img_top">
              <div className="partner_header">
                <div className="partner_header_left">
                  <div className="partner_header_title mb50">
                    <img className="partner_header_wx_icon" src={partnerHeaderWxIcon} alt="partnerHeaderWxIcon" />
                    <div className="min_app">小程序</div>
                    <div className="partner_header_title_text ml20">合作伙伴</div>
                  </div>
                  {
                    this.state.headerList.map((item,index)=>{
                      return(
                        <div className="partner_header_item mb20" key={index}>
                          <img className="partner_header_item_icon" src={partnerHeaderItemIcon} alt="partner_header_item_icon" />
                          <div className="partner_header_item_text">{item}</div>
                        </div>
                      )
                    })
                  }
                  <div className="partner_header_btn tc mt30" onClick={this.handleClick.bind(this)}>立即体验</div>
                </div>
                <img className="partner_header_img ml60" src={partnerHeaderImg} alt='partner_header_img'></img>
              </div>
              <div className="partner_flow_wrap mt100">
                <div className="partner_flow_title">合作流程</div>
                <div className="partner_flow_desc mt12">引流-营销-交易-分佣-售后</div>
                <img className="partner_flow_img mt90" src={partnerFlowImg} alt="partnerFlowImg" />
              </div>
              <div className="partner_content mt100">
                <img className="partner_content_img" src={partnerContentImg} alt="partnerContentImg" />
              </div>
            </div>
            <img className="partner_expert_img" src={partnerExpertImg} alt="partner_expert" />
            <div className="h20"></div>
            <Footer isSoftware={true} index={5} history={this.props.history}></Footer>
            <ScrollTop></ScrollTop>
          </div>
        )
    }
}