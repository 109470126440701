import React from "react";

import "./download.css";
import defaultImgUrl from "../../static/images/download_page_bg.png";
import downloadConLeftImg1 from '../../static/images/download_con_left_img1.png'
import downloadConLeftImg2 from '../../static/images/download_con_left_img2.png'
import downloadConLeftImg3 from '../../static/images/download_con_left_img3.png'
import downloadConLeftImg4 from '../../static/images/download_con_left_img4.png'

class download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundObj : {
        background: `url(${defaultImgUrl}) `,
        backgroundSize: '100%,100%',
        backgroundRepeat: 'no-repeat'
      },
      title: '剑琅管家',
      spark: '开启您的线上数字化门店管理',
      button: '立即下载',
      list: [{
        url: downloadConLeftImg1,
        math: '5万',
        tip: '美业手艺人使用'
      },{
        url: downloadConLeftImg2,
        math: '1万+',
        tip: '美业门店的选择'
      },{
        url: downloadConLeftImg3,
        math: '300万',
        tip: '会员消费者'
      },{
        url: downloadConLeftImg4,
        math: '50亿',
        tip: '年交易额'
      }]
    };
  }
  goSoftware() {
    if(!this.props.isSoftware){
      this.props.history.push({ pathname: "/software" });
    }
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }
  render() {
    return (
      <div className="download" style={this.state.backgroundObj}>
        <div className="header flex mb30">
          <p className="left">{this.state.title}</p>
          <p className="right">{this.state.spark}</p>
        </div>
        <div className="download-con flex">
          <div className="download-con-left flex">
          {this.state.list.map((item, index) => {
              return (
                <div className="flex mr70" key={index}>
                  <div className="download-con-left-img"></div>
                  <div>
                    <p className="name">{item.math}</p>
                    <p className="tip">{item.tip}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="download-con-right ml300" onClick={ () => this.goSoftware(this)}>{this.state.button}</div>
        </div>
      </div>
    );
  }
}

export default download;
