import { Component } from 'react';
import './versions.css'
import { Image  } from 'antd';
import MobileInfoToast from '../../../../components/mobileInfoToast/mobileInfoToast';
import officialLogo from '../../../../static/images/official_logo.png'
import functionModuleIcon1 from '../../../../static/images/function_module_icon1.png'
import functionModuleIcon2 from '../../../../static/images/function_module_icon2.png'
import { CheckCircleOutlined,CheckCircleFilled,CheckOutlined } from '@ant-design/icons';
import functionModuleItemIcon1 from '../../../../static/images/function_module_item_icon1.png'
import functionModuleItemIcon2 from '../../../../static/images/function_module_item_icon2.png'
import functionModuleItemIcon3 from '../../../../static/images/function_module_item_icon3.png'
import functionModuleItemIcon4 from '../../../../static/images/function_module_item_icon4.png'
import functionModuleItemIcon5 from '../../../../static/images/function_module_item_icon5.png'
import systemModuleIcon1 from '../../../../static/images/system_module_icon1.png'
import systemModuleIcon2 from '../../../../static/images/system_module_icon2.png'
import systemModuleIcon3 from '../../../../static/images/system_module_icon3.png'
import systemModuleIcon4 from '../../../../static/images/system_module_icon4.png'
import systemModuleIcon5 from '../../../../static/images/system_module_icon5.png'
import systemModuleIcon6 from '../../../../static/images/system_module_icon6.png'
import systemModuleIcon7 from '../../../../static/images/system_module_icon7.png'
import customerImg from '../../../../static/customer/customer_img.png'
import productModuleIcon1 from "../../../../static/images/product/product_module_icon1.png";
import productModuleIcon2 from "../../../../static/images/product/product_module_icon2.png";
import productModuleIcon3 from "../../../../static/images/product/product_module_icon3.png";
import productModuleIcon4 from "../../../../static/images/product/product_module_icon4.png";
import productModuleIcon5 from "../../../../static/images/product/product_module_icon5.png";
import productModuleIcon6 from "../../../../static/images/product/product_module_icon6.png";
import productModuleImg1 from "../../../../static/images/product/product_module_img1.png";
import productModuleImg2 from "../../../../static/images/product/product_module_img2.png";
import productModuleImg3 from "../../../../static/images/product/product_module_img3.png";
import productModuleImg4 from "../../../../static/images/product/product_module_img4.png";
import productModuleImg5 from "../../../../static/images/product/product_module_img5.png";
import productModuleImg6 from "../../../../static/images/product/product_module_img6.png";
export default class VersionsMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      contentList:[
        {title:'异业合作',icon:systemModuleIcon1},
        {title:'流水订单',icon:systemModuleIcon2},
        {title:'库存管理',icon:systemModuleIcon3},
        {title:'经营分析',icon:systemModuleIcon4},
        {title:'会员管理',icon:systemModuleIcon5},
        {title:'自助收银',icon:systemModuleIcon6},
        {title:'收益提现',icon:systemModuleIcon7},
      ],
      functionArr: [{
        img: functionModuleItemIcon1,
        title: '常用功能',
        list: [{
          name: '开单记账',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '流水订单',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '预约管理',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '收益提现',
          icon1: '-',
          icon2: '',
          icon3: '-',
        },{
          name: '我的薪资',
          icon1: '',
          icon2: '',
          icon3: '',
        }]
      },{
        img: functionModuleItemIcon2,
        title: '经营管理',
        list: [{
          name: '经营分析',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '客户管理',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '自助收银',
          icon1: '-',
          icon2: '',
          icon3: '-',
        },{
          name: '支出管理',
          icon1: '限时赠送',
          icon2: '限时赠送',
          icon3: '限时赠送',
        }]
      },{
        img: functionModuleItemIcon3,
        title: '营销获客',
        list: [{
          name: '营销短信',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '优惠券',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '商城',
          icon1: '-',
          icon2: '限时赠送',
          icon3: '-',
        },{
          name: '作品管理',
          icon1: '-',
          icon2: '',
          icon3: '-',
        }]
      },{
        img: functionModuleItemIcon4,
        title: '店铺管理',
        list: [{
          name: '物料商城',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '价目表',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '卡管理',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '门店管理',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '店铺装修',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '消费短信',
          icon1: '-',
          icon2: '',
          icon3: '-',
        },{
          name: '库存管理',
          icon1: '限时赠送',
          icon2: '限时赠送',
          icon3: '限时赠送',
        }]
      },{
        img: functionModuleItemIcon5,
        title: '员工管理',
        list: [{
          name: '员工管理',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '员工薪资',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '业绩排行',
          icon1: '限时赠送',
          icon2: '限时赠送',
          icon3: '限时赠送',
        },{
          name: '提成设置',
          icon1: '-',
          icon2: '',
          icon3: '-',
        },{
          name: '业绩设置',
          icon1: '',
          icon2: '',
          icon3: '',
        }]
      },{
        img: "",
        title: '其它',
        list: [{
          name: '消息入口',
          icon1: '',
          icon2: '',
          icon3: '',
        },{
          name: '“我的”入口',
          icon1: '',
          icon2: '',
          icon3: '',
        }],
      }],
      productModules:[
        {
          icon:productModuleIcon1,
          title:'开单记账',
          desc:'全程无纸化开单流程，简单方便告别手工记账错单、漏单等情况',
          list:['极简操作流程','无纸化开单','阿里云数据存储'],
          image:productModuleImg1
        },
        {
          icon:productModuleIcon2,
          title:'会员管理',
          desc:'信息录入快捷，个人档案长久留存，永不丢失，还能为顾客生成标签，做好客户分群，实现精准营销',
          list:['会员信息','消费信息','办卡·储值·折扣等'],
          image:productModuleImg2
        },
        {
          icon:productModuleIcon3,
          title:'店铺业绩（经营报表）',
          desc:'可视化的营业数据报表，简单易懂，为门店经营决策提供数据支持',
          list:['日/周/月营业报表','增长趋势分析','门店业绩报表','员工业绩报表等'],
          image:productModuleImg3
        },
        {
          icon:productModuleIcon4,
          title:'智能预约',
          desc:'客户预约项目、技师、未来事项等实时联动查询，轻松掌握门店现场资源动态，满足各类预约场景',
          list:['极速预约','到店提醒','账单推送'],
          image:productModuleImg4
        },
        {
          icon:productModuleIcon5,
          title:'智慧收银',
          desc:'快速便捷、随扫随付，免去排队等待，开启支付新风尚。经营变现，即时查询，结算明细，一目了然',
          list:['自助买单','收益提现','线上支付'],
          image:productModuleImg5
        },
        {
          icon:productModuleIcon6,
          title:'私域营销（营销获客）',
          desc:'提供拓/留/锁/升多元化营销工具，线上线下全打通，引爆门店客流，快速提升营业额',
          list:['营销短信','线上商城','自定义优惠券/代金券','公域店铺入驻','海报工坊'],
          image:productModuleImg6
        },
      ],
      versionSelectionGuideList:[
        {icon:customerImg,message:'你好啊！我这边是美容院的，想咨询一下你们家的美业软件。',left:true},
        {icon:officialLogo,message:'您好呀！欢迎光临小店！我们这里有各种版本的美业软件，绝对能满足您的需求。',left:false},
        {icon:customerImg,message:'哈哈，那我得好好了解一下。想问下那个卓越版，是不是适合我这种基础管理需求的门店啊？ ',left:true},
        {icon:officialLogo,message:'没错没错！卓越版就是专门为有基础门店管理需求设计的，操作简便，功能齐全。',left:false},
        {icon:customerImg,message:'没错没错！卓越版就是专门为有基础门店管理需求设计的，操作简便，功能齐全。',left:true},
        {icon:officialLogo,message:'掌柜版嘛，那就更高级了！它更适合有数据分析需求和营销能力的门店。',left:false},
        {icon:officialLogo,message:'您想啊，要是您的美容院生意越来越好，肯定需要更多的数据支持来制定营销策略，对吧？ ',left:false},
        {icon:customerImg,message:'哎呀，你说得对啊！我这美容院最近生意确实越来越好，数据分析确实很重要。不过，我就是怕操作太复杂了，我这把老骨头可经不起折腾啊！ ',left:true},
        {icon:officialLogo,message:'您放心吧！我们的软件都是傻瓜式操作，一学就会。而且我们还有专业的客服售后团队，随时为您解答疑问，保证让您用得顺心，用得放心！ ',left:false},
        {icon:customerImg,message:'听你这么说，我就放心多了。那我就先买个卓越版试试吧，好的话我再升级掌柜版！',left:true},
        {icon:officialLogo,message:'好的，感谢您的信任和支持！',left:false},
      ],
    };
  }
  componentDidMount() {
  }
  handleShowInfoToast() {
    let showToast = !this.state.showToast
    this.setState({
      showToast
    })
  }
  render() {
    let { contentList,functionArr,versionSelectionGuideList,showToast,productModules } = this.state;
    return (
      <div>
        {showToast&&<MobileInfoToast handleShowInfoToast={this.handleShowInfoToast.bind(this)}></MobileInfoToast>}
        <div>
              <div className='mobile_product_banner'>
                <div className='mobile_product_banner_title'>垂直美业+深耕美业</div>    
                <div className='mobile_product_banner_title' style={{textAlign:'end'}}>覆盖美业经营管理全场景</div>
                <div className='mobile_product_banner_desc'>一站式解决美业门店现有的“提效、管理、业绩增长”等难题助力商家轻松高效管理人、财、物</div>
              </div>
              <div className="mobile_product_wrap">
                <div className="mobile_product_audio">
                  
                </div>
                <div className="mobile_product_modules flex_column_center color_black">
                  {
                    productModules.map((item,index)=>{
                        return (
                          <div className="mobile_product_module_item" style={{background:index%2===0?'#F8FAFF':'#FFFFFF'}} key={index}>
                            <div className="mobile_product_module_item_card">
                              <div className="mobile_product_module_item_right"><Image preview={false} src={item.image} width={'100%'} height={'100%'} /></div>
                              <div className="mobile_product_module_item_left">
                                <div className="mobile_product_module_item_title flex"> 
                                  <img className="mobile_product_module_item_title_icon" src={item.icon} alt=''></img>{item.title}
                                </div>
                                <div className="mobile_product_module_item_desc flex">
                                  {item.desc}
                                </div>
                                <div className="mobile_product_module_item_tags">
                                {
                                  item.list.map((item1, index1) => {
                                    return <div className="mobile_product_module_item_tag" key={index1}>{item1}</div>
                                  })
                                }
                              </div>
                              <div className="mobile_product_module_item_buttons f22 mt28 tc">
                                <div className="mobile_product_module_item_button_left" onClick={()=>this.handleShowInfoToast(this)}>购买咨询</div>
                              </div>
                              </div>
                            </div>
                      </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          <div className="mobile_version_wrap flex_column_center">
            <div className="mobile_version_title fb">剑琅联盟全新定制版</div>
            <div className="mobile_version_desc">以下版本均包含</div>
            <div className="mobile_version_content flex">
              {
                contentList.map((item,index)=>{
                  return (
                    <div className="mobile_version_content_item flex_column_center" key={index}>
                      <Image className='mobile_version_content_item_icon' preview={false} src={item.icon} alt="" />
                      <div className="mobile_version_content_item_title">{item.title}</div>
                    </div>
                  )
                })
              }
            </div>
            <div className='mobile_version_detail_card'>
              <div className='mobile_version_detail_card_header'></div>
              <div className='mobile_version_detail_card_content flex_column_center'>
                <div className='mobile_version_detail_card_content_title'>卓越版</div>
                <div className='mobile_version_detail_card_content_desc'>适用于有基础店务管理需求的单店</div>
                <div className='mobile_version_detail_card_price'>￥<div style={{fontSize:'1.2rem'}}>?</div>/年</div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>简单便捷的收银功能</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>会员精细化运营</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>员工高效管理</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>X个营销工具满足日常基础营销需求</div>
                </div>
                <div className='mobile_version_detail_card_content_btn_wrap tc'>
                  <div className='mobile_version_detail_card_content_btn1' onClick={()=>this.handleShowInfoToast(this)}>购买咨询</div>
                </div>
              </div>
            </div>
            <div className='mobile_version_detail_card'>
              <div className='mobile_version_detail_card_header' style={{background:'#FF7B29'}}></div>
              <div className='mobile_version_detail_card_content flex_column_center'>
                <div className='mobile_version_detail_card_content_title'>掌柜版</div>
                <div className='mobile_version_detail_card_content_desc'>适用于有数据分析需求和强营销的单店/连锁店</div>
                <div className='mobile_version_detail_card_price'>￥<div style={{fontSize:'1.2rem'}}>?</div>/年</div>
                <div className='mobile_version_detail_card_tip'>已包含<div style={{fontSize:'0.45rem',fontWeight:'bold'}}>卓越版</div>所有功能权益</div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>智慧收银</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>会员精细化管理</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>员工高效管理</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>经营报表分析</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>可视化数据分析支持门店经营决策</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>X个营销工具可实现客量和业绩双增长</div>
                </div>
                <div className='mobile_version_detail_card_content_btn_wrap tc'>
                  <div className='mobile_version_detail_card_content_btn1' onClick={()=>this.handleShowInfoToast(this)}>购买咨询</div>
                  <div className='mobile_version_detail_card_content_btn2' onClick={()=>this.handleShowInfoToast(this)}>直接购买</div>
                </div>
              </div>
            </div>
            <div className='mobile_version_detail_card'>
              <div className='mobile_version_detail_card_header' style={{background:'#FF7B29'}}></div>
              <div className='mobile_version_detail_card_content flex_column_center'>
                <div className='mobile_version_detail_card_content_title'>定制版</div>
                <div className='mobile_version_detail_card_content_desc'>适用于营销需求多样化的高端单店/连锁店</div>
                <div className='mobile_version_detail_card_ask_btn tc' onClick={()=>this.handleShowInfoToast(this)}>详询定价</div>
                <div className='mobile_version_detail_card_tip'>已包含<div style={{fontSize:'0.45rem',fontWeight:'bold'}}>卓越版</div>所有功能权益</div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>智慧收银</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>会员精细化管理</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>员工高效管理</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>经营报表分析</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleOutlined style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>可视化数据分析支持门店经营决策</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleFilled style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>定制化营销方案强化品牌专属调性</div>
                </div>
                <div className='mobile_version_detail_card_item'>
                  <CheckCircleFilled style={{color:'#387AF7'}} />
                  <div className='mobile_version_detail_card_item_text'>全部营销工具满足市场多样化营销需求</div>
                </div>
              </div>
            </div>

            <div className='mobile_version_table_wrap'>
              <div className='mobile_version_table_header tc'>
                <div className='mobile_version_table_header_item'>
                  <div className='mobile_version_table_header_item_img'>功能模块</div>
                </div>
                <div className='mobile_version_table_header_item'>
                  <img className='mobile_version_table_header_item_icon' src={functionModuleIcon1} alt=''></img>
                  <div className='mobile_version_table_header_item_price'>￥<p>?</p>/年/店</div>
                  <div className='mobile_version_table_header_item_desc'>赠送：货架/剪刀包*2</div>
                </div>
                <div className='mobile_version_table_header_item'>
                  <img className='mobile_version_table_header_item_icon' src={functionModuleIcon2} alt=''></img>
                  <div className='mobile_version_table_header_item_price'>￥<p>?</p>/年/店</div>
                  <div className='mobile_version_table_header_item_desc'>赠送：货架/剪刀包*2</div>
                </div>
                <div className='mobile_version_table_header_item'>
                  <img className='mobile_version_table_header_item_icon' src={functionModuleIcon1} alt=''></img>
                  <div className='mobile_version_table_header_item_price'>￥<p>?</p>/年/店</div>
                  <div className='mobile_version_table_header_item_desc'>赠送：货架/剪刀包*2</div>
                </div>
              </div>

              <div className="mobile_function_module_table">
                {
                  functionArr.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="mobile_function_module_table_title flex mt50 ml25">
                          {item.img&&<div className="mobile_function_module_table_title_icon"><Image preview={false} src={item.img} /></div>}
                          <div className="ml20">{item.title}</div> 
                        </div>
                        <div className="mobile_function_module_table_body mt20">
                          {
                            item.list.map((item1, index1) => {
                              return (
                                <div className="mobile_function_module_table_line flex_between tc" style={{background: index1 % 2 === 0 ? '#F4F9FF' : 'white'}} key={index1}>
                                  <div className="mobile_function_module_table_line_name color_333">{item1.name}</div>
                                  <div className="mobile_function_module_table_line_icon1 flex_absolute_center ">
                                    {
                                      item1.icon1|| <div className="mobile_function_module_table_line_check_img flex_absolute_center"><CheckOutlined style={{color: '#fff'}} /></div>
                                    }
                                  </div>
                                  <div className="mobile_function_module_table_line_icon2 flex_absolute_center ">
                                    {
                                      item1.icon2|| <div className="mobile_function_module_table_line_check_img flex_absolute_center"><CheckOutlined style={{color: '#fff'}} /></div>
                                    }
                                  </div>
                                  <div className="mobile_function_module_table_line_icon3 flex_absolute_center ">
                                    {
                                      item1.icon3|| <div className="mobile_function_module_table_line_check_img flex_absolute_center"><CheckOutlined style={{color: '#fff'}} /></div>
                                    }
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="mobile_version_selection_guide mt50 flex_column_center">
              <div className="mobile_version_selection_guide_title fb f40 color_black tc mb70">版本选择指南</div>
              {
                versionSelectionGuideList.map((item,index)=>{
                  return(
                    <div className="mobile_version_selection_guide_item flex mb100 f24" key={index} style={{justifyContent: item.left ?'flex-start':'flex-end'}}>
                      {item.left&&<div className="mobile_version_selection_guide_item_icon mr50"><Image width={'100%'} height={'100%'} src={item.icon} preview={false} /></div>}
                      <div className={`mobile_version_selection_guide_item_content fb f24 ${item.left ?'' : 'mobile_version_selection_guide_item_content_right'}`}>{item.message}</div>
                      {!item.left&&<div className="mobile_version_selection_guide_item_icon ml50"><Image width={'100%'} height={'100%'} src={item.icon} preview={false} /></div>}
                    </div>
                  )
                })
              }
            </div>
          </div>
      </div>
    );
  };
}