import { Component } from 'react';
import { Image, Popover  } from 'antd';
import './service.css'
import systemStateIcon1 from "../../../../static/images/product/system_state_icon1.png";
import systemStateIcon2 from "../../../../static/images/product/system_state_icon2.png";
import systemStateIcon3 from "../../../../static/images/product/system_state_icon3.png";
import systemStateIcon4 from "../../../../static/images/product/system_state_icon4.png";
import systemStateIcon5 from "../../../../static/images/product/system_state_icon5.png";
import systemStateIcon6 from "../../../../static/images/product/system_state_icon6.png";
import systemStateIcon7 from "../../../../static/images/product/system_state_icon7.png";
import ISOIcon from "../../../../static/images/product/ISO_logo.png";
import AAAIcon from "../../../../static/images/product/AAA_logo.png";
import qpIcon from "../../../../static/images/product/qp_logo.png";
import { _ } from "../../../../common/request";
export default class ServiceMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileStatusList:[
        {title:'店铺',desc:'店铺主页、微页面的浏览',icon:systemStateIcon1},
        {title:'商品',desc:'商品详情的浏览',icon:systemStateIcon2},
        {title:'交易',desc:'购物车、下单、订单核销',icon:systemStateIcon3},
        {title:'支付',desc:'买家下单付款',icon:systemStateIcon4},
        {title:'账号',desc:'买家的注册、登录及店铺管理',icon:systemStateIcon5},
        {title:'营销',desc:'优惠券/码、限时折扣、满减送、拼团等营销玩法',icon:systemStateIcon6},
        {title:'消息',desc:'智能客服咨询服务等消息业务',icon:systemStateIcon7},
      ],
      systemStateCollectList:[],
      internationalCertificationList:[
        {title:'Iso27001',desc:'信息安全管理体系认证',icon:ISOIcon},
        {title:'Iso9001',desc:'信息安全管理体系认证',icon:ISOIcon},
        {title:'',desc:'省级高新技术企业研究开发中心',icon:qpIcon},
        {title:'AAA级',desc:'AAA级信用企业',icon:AAAIcon},
      ],
    };
  }
  componentDidMount() {
    this.getServerState();
  }

  // 获取服务器状态
  getServerState(){
    let params = {
      env_type:"jy-prd"
    }
    _.fetchServerStateList(params).then(res=>{
      if(res.success === '0'){
        let arr = res.val
        this.setState({
          systemStateCollectList: arr,
        })
      }
    })
  }

  render() {
    let {mobileStatusList,systemStateCollectList,internationalCertificationList} = this.state;
    return (
      <div>
          <div className="mobile_service_wrap flex_column_center">
            <div className="mobile_service_title">系统状态</div>
            <div className='mobile_service_status_wrap'>
              {
                mobileStatusList.map((item,index)=>{
                  return(
                    <div className="mobile_service_status_item" key={index}>
                      <Image className='mobile_service_status_item_icon' preview={false} src={item.icon} />
                      <div className="mobile_service_status_item_title">{item.title}</div>
                      <div className="mobile_service_status_item_desc">{item.desc}</div>
                    </div>
                  )
                })
              }
            </div>
            <div className='mobile_service_status_month_warp flex_column_center'>
              <div className='mobile_service_status_month_title'>90天状态汇总</div>
              <div className='mobile_state_collect_card_item'>
                {
                  systemStateCollectList.splice(0,30).map((item,index)=>{
                    return(
                      <Popover content={'服务器'+item.describe} title={item.date} key={index}>
                        <div className={`state_dote ${item.describe==='正常'?'':'state_dote_abnormality'}`}></div>
                      </Popover>
                    )
                  })
                }
              </div>
              <div className='mobile_state_collect_card_item'>
                {
                  systemStateCollectList.splice(0,30).map((item,index)=>{
                    return(
                      <Popover content={'服务器'+item.describe} title={item.date} key={index}>
                        <div className={`state_dote ${item.describe==='正常'?'':'state_dote_abnormality'}`}></div>
                      </Popover>
                    )
                  })
                }
              </div>
              <div className='mobile_state_collect_card_item'>
                {
                  systemStateCollectList.splice(0,30).map((item,index)=>{
                    return(
                      <Popover content={'服务器'+item.describe} title={item.date} key={index}>
                        <div className={`state_dote ${item.describe==='正常'?'':'state_dote_abnormality'}`}></div>
                      </Popover>
                    )
                  })
                }
              </div>
            </div>

            <div className='mobile_international_certification flex_column_center'>
              <div className='mobile_international_certification_title'>获得多项国际认证，堪比银行级的安全保障</div>
              <div className='mobile_international_certification_card'>
                {
                  internationalCertificationList.map((item,index)=>{
                    return (<div className='mobile_international_certification_item flex_column_center'  key={index}>
                      <Image className='mobile_international_certification_item_icon' preview={false} src={item.icon} />
                      {item.title&&<div className='mobile_international_certification_item_title'>{item.title}</div>}
                      <div className='mobile_international_certification_item_desc tc' style={{width:index===2?'3rem':''}}>{item.desc}</div>
                    </div>)
                  })
                }
              </div>
            </div>
          </div>
      </div>
    );
  };
}