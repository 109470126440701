import { Component } from 'react';
import { Image  } from 'antd';
import './safe.css'
import motherOfDragon from "../../../../static/images/product/mother_of_dragon.png";
import safeTabItemIcon1 from "../../../../static/images/product/safe_tab_item_icon1.png";
import safeTabItemIcon2 from "../../../../static/images/product/safe_tab_item_icon2.png";
import safeTabItemIcon3 from "../../../../static/images/product/safe_tab_item_icon3.png";
import safeTabItemIcon4 from "../../../../static/images/product/safe_tab_item_icon4.png";
import safeTabItemImg1 from "../../../../static/images/product/safe_tab_item_img1.png";
import safeTabItemImg2 from "../../../../static/images/product/safe_tab_item_img2.png";
import safeTabItemImg3 from "../../../../static/images/product/safe_tab_item_img3.png";
import safeTabItemImg4 from "../../../../static/images/product/safe_tab_item_img4.png";
export default class SafeMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descList:[
        {icon:safeTabItemIcon1,title:'护航运作稳定',desc:'如遇操作中断可获补偿'},
        {icon:safeTabItemIcon2,title:'护航数据安全',desc:'安全加密，实时备份'},
        {icon:safeTabItemIcon3,title:'护航商户体验',desc:'使用中有效防止广告弹出'},
        {icon:safeTabItemIcon4,title:'护航更新速度',desc:'系统实时更新，加载快速'},
      ],
      safeModules:[
        {
          title:'为商户运作稳定护航',
          desc:'系统稳定，运作高效，实时展示商家运行状态，如遇运作中断可获相应的补偿。',
          image:safeTabItemImg1
        },
        {
          title:'为商家数据安全护航',
          desc:'数据安全加密，通过实时备份等方式及时保障店铺业务的数据安全。',
          image:safeTabItemImg2
        },
        {
          title:'为你的商户体验护航',
          desc:'店铺上总出现突然弹出的广告? 剑琅全站有效制止广告乱入。',
          image:safeTabItemImg3
        },
        {
          title:'为更新速度护航',
          desc:'系统页面加载速度快，软件免费实时更新',
          image:safeTabItemImg4
        },
      ]
    };
  }
  goToServiceState(){
    if(this.props.handleChangeChildrenTab !== undefined){
      this.props.handleChangeChildrenTab(4);
    }
  }
  render() {
    let { descList, safeModules } = this.state;
    return (
      <div>
          <div className="mobile_safe_wrap">
            <div className="mobile_safe_header">
              <img  className='mobile_safe_header_img' src={motherOfDragon} alt=''></img>
              <div className='mobile_safe_header_title'>龙妈护航</div>
              <div className='mobile_safe_header_desc'>因为专业所以靠谱，为商户们保驾护航</div>
              <div className='mobile_safe_header_btn tc' onClick={()=>this.goToServiceState(this)}>了解运营状态</div>
            </div>
            <div className='mobile_safe_desc_wrap flex'>
              {
                descList.map((item,index)=>{
                  return (
                    <div className='mobile_safe_desc_item flex_column_center' key={index}>
                      <div className='mobile_safe_desc_item_icon'>
                        <Image preview={false} src={item.icon} alt=""/>
                      </div>
                      <div className='mobile_safe_desc_item_title'>
                        {item.title}
                      </div>
                      <div className='mobile_safe_desc_item_desc'>
                        {item.desc}
                      </div>
                    </div>)
                })
              }
            </div>

            <div className='mobile_safe_modules_list flex_column_center'>
              {
                safeModules.map((item,index)=>{
                  return (<div className='mobile_safe_modules_item' key={index}>
                    {
                      index % 2 === 0 ? <img className='mobile_safe_modules_img' alt='' src={item.image} /> :null
                    }
                    <div className='mobile_safe_modules_content'>
                      <div className='mobile_safe_modules_title'>{item.title}</div>
                      <div className='mobile_safe_modules_desc'>{item.desc}</div>
                    </div>
                    {
                      index % 2 !== 0 ? <img className='mobile_safe_modules_img' alt='' src={item.image} /> :null
                    }
                  </div>)
                })
              }
            </div>
          </div>
      </div>
    );
  };
}