import React from "react";

import "./footer.css";
import { CaretRightOutlined } from '@ant-design/icons';
import AppDownloadImg from "../../static/footer/APP_download_er_code.png";
import officialDownloadImg from "../../static/footer/official_accounts_er_code.png";
import telIcon from "../../static/footer/footer_tel_icon.png";
import homeIcon from "../../static/footer/footer_home_icon.png";
import positionIcon from "../../static/footer/footer_position_icon.png";
import footer2TopBlockIcon1 from "../../static/footer/footer2_top_block_icon1.png";
import footer2TopBlockIcon2 from "../../static/footer/footer2_top_block_icon2.png";
import footer2TopBlockIcon3 from "../../static/footer/footer2_top_block_icon3.png";
import footer2TopBlockIcon4 from "../../static/footer/footer2_top_block_icon4.png";
import footer2TopBlockIcon5 from "../../static/footer/footer2_top_block_icon5.png";
import footer2TopBlockIcon6 from "../../static/footer/footer2_top_block_icon6.png";
import footer2TopBlockIcon7 from "../../static/footer/footer2_top_block_icon7.png";
import footer2TopBlockIcon8 from "../../static/footer/footer2_top_block_icon8.png";
import douYinERcode from "../../static/footer/dou_yin_ERcode.png";

class footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: 0,
      day: 0,
      picList: [
        {
          name: "APP下载",
          url: AppDownloadImg,
        },
        {
          name: "关注公众号",
          url: officialDownloadImg,
        },
        {
          name: "抖音",
          url: douYinERcode,
        },
      ],
      contactList: [
        {
          name: "联系我们：",
          name1: "021-6331 6099",
          url: telIcon,
        },
        {
          name: "上海集勇信息科技有限公司",
          name1: "",
          url: homeIcon,
        },
        {
          name: "上海市黄浦区淮海中路222号力宝广场13楼",
          name1: "",
          url: positionIcon,
        },
      ],
      detail: {
        service: {
          title: "服务",
          content: ["店铺代运营"],
        },
        product: {
          title: "产品",
          content: ["剑琅管家", "医美医务系统", "靓丽美集"],
        },
        understand: {
          title: "进一步了解我们",
          content: ["软件下载","关于我们"],
        },
      },
      consultList:[
        {year:2022,text:'华裔小姐总冠军Bella魏诗佳也共同见证丽妍雅集Rubis SPA高光时刻'},
        {year:2021,text:'上海北外滩新地标太阳宫也举行了开业剪彩活动，品牌多年挚友实力演员宋佳耀眼现身'},
        {year:2021,text:'丽妍雅集连开4家新店，并邀请著名演员孙俪亲临现场助阵，为上海前滩太古里门店剪彩'},
        {year:2019,text:'丽妍雅集Rubis SPA于加拿大多伦多举办七国美容专家高峰论坛'},
      ],
      functionList: [
        {
          title: "数据安全",
          url: footer2TopBlockIcon1,
        },
        {
          title: "快速访问",
          url: footer2TopBlockIcon2,
        },
        {
          title: "技术过硬",
          url: footer2TopBlockIcon3,
        },
        {
          title: "系统稳定",
          url: footer2TopBlockIcon4,
        },
        {
          title: "智能高效",
          url: footer2TopBlockIcon5,
        },
        {
          title: "快速迭代",
          url: footer2TopBlockIcon6,
        },
        {
          title: "实时安装",
          url: footer2TopBlockIcon7,
        },
        {
          title: "专属客服",
          url: footer2TopBlockIcon8,
        },
      ],
      filings: ["Copyright © 2017-2022 Inc. ", "沪ICP备17056175号-1", ""],
    };
  }

  // 跳转下载页面
  goSoftware() {
    if (!this.props.isSoftware) {
      this.props.history.push({ pathname: "/software" });
    }
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }
  
  clickTab(index){
    localStorage.setItem('currentTabsInIndex',index)
    this.props.history.push({ pathname: "/product" });
  }

  goToService(index) {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    if(this.props.index === 2){
      this.props.handleChangeTab(index)
      return
    }
    this.clickTab(index)
  }

  
  goToHome(index) {
    if (this.props.index !== 0){
      this.props.history.push({ pathname: "/home" });
      localStorage.setItem("fromOtherPage", JSON.stringify({visible:true,value:index}));
      return
    }
    localStorage.setItem("fromOtherPage",  JSON.stringify({visible:false,value:index}));
    if(this.props.positionSystem !== undefined && this.props.index === 0){
      this.props.positionSystem();
    }
  }
  goToAboutUs(index) {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    if (this.props.index === 4&&index===1) return;
    if (this.props.index===5&&index===0) return;
    this.props.history.push({ pathname: `/${index===1?'aboutMe':'software'}` });
  }
  render() {
    return (
      <div className="footer-wrop">
        <div className="footer1">
          <div className="footer1-left">
            <div className="footer1-one">
              <div className="footer-top flex">
                {this.state.picList.map((item, index) => {
                  return (
                    <div
                      className="pic mr40"
                      key={index}
                      onClick={() => this.goSoftware.bind(this)}
                    >
                      <img className="pic" src={item.url} alt=""></img>
                      <p className="pic-name mt12">{item.name}</p>
                    </div>
                  );
                })}
                <div className="footer1-two ml60">
                  <div className="footer1-con">
                    <div className="title">{this.state.detail.service.title}</div>
                    {this.state.detail.service.content.map((item, index) => {
                      return (
                        <div
                          className="name"
                          key={index}
                          onClick={this.goToService.bind(this,3)}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="footer1-two ml50">
                  <div className="footer1-con">
                    <div className="title">{this.state.detail.product.title}</div>
                    {this.state.detail.product.content.map((item, index) => {
                      return (
                        <div
                          className="name"
                          key={index}
                          onClick={index===2 ? this.goToHome.bind(this,index) : this.goToService.bind(this,index)}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="footer1-two ml50">
                  <div className="footer1-con">
                    <div className="title">{this.state.detail.understand.title}</div>
                    {this.state.detail.understand.content.map((item, index) => {
                      return (
                        <div
                          className="name"
                          key={index}
                          onClick={this.goToAboutUs.bind(this,index)}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              {this.state.contactList.map((item, index) => {
                return (
                  <div className="illustrate flex" key={index} style={{marginLeft:index===0?'0px':'44px'}}>
                    <img className="icon" src={item.url} alt=""></img>
                    <p className="illustrate-name ml10">
                      {item.name} <span className="span"  style={{fontSize:index===0?'26px':'18px'}}>{item.name1}</span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="footer1-right ml60">
              <div className="footer1-right-header mb10">
                <div className="footer1-right-header-title">资讯</div>
                <div className="footer1-right-header-btn mr100 f12" onClick={this.goToAboutUs.bind(this,1)}>查看更多<CaretRightOutlined style={{fontSize:'14px'}}/></div>
              </div>
              {
                this.state.consultList.map((item,index)=>{
                  return (
                    <div className="footer1-right-item" style={{borderBottom: index===this.state.consultList.length-1 ? 'none':'1px solid rgba(153, 153, 153, 0.5)'}} key={index}>
                      <div className="mt15">{item.text}</div>
                      <div className="mt5">{item.year}</div>
                    </div>
                  )
                })
              }
          </div>
        </div>
        <div className="footer2">
          <div className="footer2-top flex flex_between">
            {this.state.functionList.map((item, index) => {
              return (
                <div className="block mr30" key={index}>
                  <div className="block-img-wrap flex_absolute_center">
                    <img className="block-img" src={item.url} alt="" />
                  </div>
                  <p className="block-name mt10">{item.title}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="footer3">
          <p className="line"></p>
          <div
            className="footer3-con flex"
          >
            {this.state.filings.map((item, index) => {
              return (
                <p className="footer3-name pl100 pb35" key={index}>
                  {item}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default footer;
