import React, { Component } from "react";
import { _ } from "../../../common/request";
import { message } from "antd";
// import { Select } from 'antd';
import "./thirdpartnar.css";
import floatSuccessIcon from '../../../static/images/float_success_icon.png'
import mobile_toast_close_icon from "../../../static/images/mobile_toast_close_icon.png";
import thirdPartnerBg2 from "../../../static/images/thirdpartnar/third_partner_bg2.png"
import thirdPartnerBg3 from "../../../static/images/thirdpartnar/third_partner_bg3.png"
import thirdPartnerBg4 from "../../../static/images/thirdpartnar/third_partner_bg4.png"
import thirdPartnerBg5 from "../../../static/images/thirdpartnar/third_partner_bg5.png"
import thirdPartnerBg6 from "../../../static/images/thirdpartnar/third_partner_bg6.png"
import thirdPartnerBg7 from "../../../static/images/thirdpartnar/third_partner_bg7.png"
import thirdPartnerBg8 from "../../../static/images/thirdpartnar/third_partner_bg8.png"
import thirdPartnerBg9 from "../../../static/images/thirdpartnar/third_partner_bg9.png"
import thirdPartnerBg10 from "../../../static/images/thirdpartnar/third_partner_bg10.png"
import thirdPartnerBg11 from "../../../static/images/thirdpartnar/third_partner_bg11.png"
import thirdPartnerBg12 from "../../../static/images/thirdpartnar/third_partner_bg12.png"
import thirdPartnerBg13 from "../../../static/images/thirdpartnar/third_partner_bg13.png"
import thirdPartnerBg14 from "../../../static/images/thirdpartnar/third_partner_bg14.png"
import thirdPartnerBg15 from "../../../static/images/thirdpartnar/third_partner_bg15.png"
import thirdPartnerBg16 from "../../../static/images/thirdpartnar/third_partner_bg16.png"
import thirdPartnerBg17 from "../../../static/images/thirdpartnar/third_partner_bg17.png"

export default class ThirdPartner extends Component {
    constructor(props){
        super(props);
        this.state = {
          name: "",
          mobile: "",
          shopName: "",
          role: 1,
          preSuccess: false
        }
    }

    handleClick(){
      if(!this.state.name){
        return message.error("请输入您的姓名");
      }
      if(!this.state.mobile){
        return message.error("请输入您的手机号");
      }
      let reg= /^1\d{10}$/
      if(!reg.test(this.state.mobile)){
        return message.error("请输入正确的手机号");
      }
      this.handleClickSavePhone()

    }
    handleSelectChange(e){
      this.setState({
        role: e.target.value
      })
    }
    
    
    handleClickSavePhone(){
      let num = this.state.mobile.slice(-4);
      let params = {
        leadsSource:11,
        industryType:4,
        customerIntention:1,
        customerName:'客戶'+num + this.state.name,
        shopName:'店铺'+this.state.shopName,
        shopRole: this.state.role,
        mobile: this.state.mobile
      }
      _.saveExternalLeads(params).then(res=>{
        if(res.code === '200'){
          this.setState({
            preSuccess: true,
          })
        }
      })
    }
    render() {
        return (
          <div className="thirdpartnar">
            <div className="thirdpartnar_header">
              <div className="free_get_form">
                <input className="free_get_form_input" placeholder="请输入您的姓名" onChange={(e)=>{this.setState({name:e.target.value})}}></input>
                <input className="free_get_form_input" placeholder="请输入您的手机号"  onChange={(e)=>{this.setState({mobile:e.target.value})}}></input>
                <input className="free_get_form_input" placeholder="美团/点评线上店铺名称" onChange={(e)=>{this.setState({shopName:e.target.value})}}></input>
                <select className="free_get_form_input" placeholder="请选择您在店铺的角色" onChange={(value)=>this.handleSelectChange(value)}>
                  <option value="1">老板</option>
                  <option value="2">店长</option>
                  <option value="3">店员</option>
                </select>
              </div>
              <div className="free_get_button tc f16 color_white" onClick={this.handleClick.bind(this)}>立即获取</div>
            </div>
            <img className="third_img mt60" src={thirdPartnerBg2} alt=""></img>
            <img className="third_img mt120" src={thirdPartnerBg3} alt=""></img>
            <img className="third_img mt140" src={thirdPartnerBg4} alt=""></img>
            <img className="third_img mt100" src={thirdPartnerBg5} alt=""></img>
            <img className="third_img" src={thirdPartnerBg6} alt=""></img>
            <img className="third_img" src={thirdPartnerBg7} alt=""></img>
            <img className="third_img" src={thirdPartnerBg8} alt=""></img>
            <img className="third_img" src={thirdPartnerBg9} alt=""></img>
            <img className="third_img" src={thirdPartnerBg10} alt=""></img>
            <img className="third_img" src={thirdPartnerBg11} alt=""></img>
            <img className="third_img" src={thirdPartnerBg12} alt=""></img>
            <img className="third_img" src={thirdPartnerBg13} alt=""></img>
            <img className="third_img" src={thirdPartnerBg14} alt=""></img>
            <img className="third_img" src={thirdPartnerBg15} alt=""></img>
            <img className="third_img" src={thirdPartnerBg16} alt=""></img>
            <img className="third_img" src={thirdPartnerBg17} alt=""></img>
            <div className="free_get__bottom_form">
              <div className="bottom_form_title f28 color_black">把需求 交给剑琅联盟</div>
              <div className="form_item">
                <input className="free_get_form_item_input" placeholder="请输入您的姓名" onChange={(e)=>{this.setState({name:e.target.value})}}></input>
                <input className="free_get_form_item_input" placeholder="请输入您的手机号" onChange={(e)=>{this.setState({mobile:e.target.value})}}></input>
              </div>
              <input className="free_get_bottom_form_input" placeholder="美团/点评线上店铺名称" onChange={(e)=>{this.setState({shopName:e.target.value})}}></input>
              <select className="free_get_bottom_form_input" placeholder="请选择您在店铺的角色" onChange={(value)=>this.handleSelectChange(value)}>
                  <option value="1">老板</option>
                  <option value="2">店长</option>
                  <option value="3">店员</option>
                </select>
              <div className="bottom_form_btn tc f16 color_white" onClick={this.handleClick.bind(this)}>立即获取</div>
            </div>
            {this.state.preSuccess&&<div className="success_toast_wrap">
              <div className="success_toast_card flex_column_center">
                <div className="success_toast_title mt50">提交成功</div>
                <img className="success_toast_icon mt50" src={floatSuccessIcon} alt=""></img>
                <div className="success_toast_desc mt15">稍后会电话与您沟通详情～</div>
                <div className="success_toast_close mt60 tc" onClick={()=>{this.setState({preSuccess:false})}}>我知道了</div>
                <img className="success_toast_hotline mt120" src={mobile_toast_close_icon} alt="" onClick={()=>{this.setState({preSuccess:false})}}></img>
              </div>
            </div>}
          </div>
        )
    }
}