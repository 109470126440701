import { Component } from 'react';
import { Image } from "antd";
import { UnorderedListOutlined,CloseOutlined,RightOutlined,DownOutlined } from '@ant-design/icons';
import './mobileHeader.css';
import { Popup,List } from 'antd-mobile'
import officialLogo from '../../static/images/official_logo.png'

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      currentMenuIndex: null,
      currentMenuChildIndex: null,
      menu: [
        {index: 0,name: "首页",showList: false,children: []},
        {index: 1,name: "客户成功故事",showList: false,children: []},
        { index: 2,
          name: "产品与服务",
          showList: false,
          children: [
            { index: 0,name: "剑琅管家", icon:''},
            {index: 1,name: "医美医务系统",icon: ''},
            {index: 2,name: "安全保障与护航",icon: ''},
            // {index: 3,name: "代运营服务",icon: ''}
          ]
        },
        { index: 3,name: "合作伙伴入驻",showList: false,children: []},
        { index: 4,name: "关于我们",showList: false,children: []},
        { index: 5, name: "软件下载", showList: false,children: []}]
    };
  }
  componentDidMount() {
    this.setState({
      currentMenuIndex: this.props.index,
      currentTabsInIndex:localStorage.getItem('currentTabsInIndex')*1 || 0
    })
  }
  chooseTab(index){
    if(index === 0) {
      this.props.history.push({ pathname: "/home" });
    }  else if(index === 1) {
      this.props.history.push({ pathname: "/customerSuccess" });
    }  else if(index === 2) {
      let array = this.state.menu;
      array[2].showList = !array[2].showList;
      this.setState({menu:array})
    } else if(index === 3) {
      this.props.history.push({ pathname: "/partnerSettlement" });
    } else if(index === 4) {
      this.props.history.push({ pathname: "/aboutMe" });
    }  else if(index === 5) {
      this.props.history.push({ pathname: "/software" });
    }
    if(index === 2) return
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    this.setState({
      showPopup: false
    })
    window.scrollTo({
      top: 0, 
      behavior: 'auto',
    });
  }

  handleChildrenItem(index){
    this.props.history.push({ pathname: `/product/${index}` });
    if(this.props.handleChangeChildrenTab !== undefined){
      this.props.handleChangeChildrenTab(index);
    }
    localStorage.setItem('currentTabsInIndex',index)
    this.setState({
      currentMenuChildIndex: index,
      showPopup: false
    })
  }
  render() {
    let {showPopup,menu,currentMenuIndex,currentMenuChildIndex} =this.state;
    return (
      <div className="header_wrap flex_between">
          <div className='flex_center'>
            <Image className='mobile_header_logo' preview={false} src={officialLogo} />
            <div className='mobile_header_name'>剑琅联盟</div>
          </div>
          <UnorderedListOutlined className='mobile_order_list_icon' onClick={() => {
                this.setState({showPopup:true})
              }} />
          <Popup
            visible={showPopup}
            onMaskClick={() => {
              this.setState({showPopup:false})
            }}
            position='right'
            bodyStyle={{ width: '100vw' }}
          >
            <div className='mobile_order_list_close'>
              <CloseOutlined onClick={() => {this.setState({showPopup:false})}} />
            </div>
            <div className='mobile_order_list_wrap'>
              <List className='mobile_order_list'>
                {
                  menu.map((item, index) => {
                    return (<div key={index}>
                    <List.Item className='mobile_order_list_item_item' arrow={false} onClick={this.chooseTab.bind(this, index)} extra={item.showList ? <DownOutlined style={{ fontSize: '0.3rem' }}  /> : <RightOutlined style={{ fontSize: '0.3rem' }}  />}>
                      <div className='mobile_order_list_item' style={{color: currentMenuIndex===index?'#4588FF':'#333333'}}>
                        <p>{item.name}</p>
                      </div>
                    </List.Item>
                    {
                      item.showList&&item.children.length > 0&&item.children.map((item1,index1)=>{
                        return <div className='mobile_order_list_item_children' style={{color: currentMenuChildIndex===index1?'#4588FF':'#333333'}} key={index1} onClickCapture={this.handleChildrenItem.bind(this,index1)}>
                        <p>{item1.name}</p>
                        <RightOutlined style={{ fontSize: '0.3rem' }}  />
                      </div>
                      })
                      
                      }</div>
                    )
                  })
                }
              </List>
            </div>
          </Popup>
      </div>
    );
  };
}