import { Component } from 'react';
import MobileHeader from '../../../components/mobileHeader/mobileHeader';
import MobileFooter from '../../../components/mobileFooter/mobileFooter';
import MobileInfoToast from '../../../components/mobileInfoToast/mobileInfoToast';
import buyPathCardItemIcon1 from "../../../static/images/software/buy_path_card_item_icon1.png"
import buyPathCardItemIcon2 from "../../../static/images/software/buy_path_card_item_icon2.png"
import softwareModuleIcon1 from "../../../static/images/system_module_icon1.png"
import softwareModuleIcon2 from "../../../static/images/system_module_icon2.png"
import softwareModuleIcon3 from "../../../static/images/system_module_icon3.png"
import softwareModuleIcon4 from "../../../static/images/system_module_icon4.png"
import softwareModuleIcon5 from "../../../static/images/system_module_icon5.png"
import softwareModuleIcon6 from "../../../static/images/system_module_icon6.png"
import softwareModuleIcon7 from "../../../static/images/system_module_icon7.png"
import './software.css'
import { Image  } from 'antd';
export default class SoftwareMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToast: false,
      functionList:[
        {icon:softwareModuleIcon1,name:'异业合作'},
        {icon:softwareModuleIcon2,name:'流水订单'},
        {icon:softwareModuleIcon3,name:'库存管理'},
        {icon:softwareModuleIcon4,name:'经营分析'},
        {icon:softwareModuleIcon5,name:'会员管理'},
        {icon:softwareModuleIcon6,name:'自助收银'},
        {icon:softwareModuleIcon7,name:'收益提现'},
      ]
    };
  }
  jumpToMore(){
    window.open('https://jianlanglianmeng.tmall.com/shop/view_shop.htm?spm=a21n57.1.3.1.69fb523chUdk0I&appUid=RAzN8HWSF3zy9KLv7xfjM1hLWS4nTHnbuwAR57Bxaan612EzV9A')
  }
  handleShowInfoToast() {
    let showToast = !this.state.showToast
    this.setState({
      showToast
    })
  }
  render() {
    let {functionList,showToast} = this.state;
    return (
      <div className="page_mobile">
        <MobileHeader MobileHeader index={5} history={this.props.history}></MobileHeader>
        {showToast&&<MobileInfoToast handleShowInfoToast={this.handleShowInfoToast.bind(this)}></MobileInfoToast>}
          <div className="mobile_software_wrap">
            <div className="mobile_software_title mt60">剑琅联盟全新定制版</div>
            <div className='mobile_software_title_desc mt40'>我们兼容：Windows 、Mac 、iOS 、Android</div>

            <a className="free" href="https://download.round-table-union.com/download/rtb.html">
              <span>免费下载</span>
            </a>

            <div className='mobile_software_download_card flex'>
              {
                functionList.map((item,index)=>{
                  return (
                    <div className='mobile_software_download_card_item flex_column_center' key={index}>
                      <Image preview={false} src={item.icon} width={'0.8rem'} height={'0.8rem'} />
                      <div className='mobile_software_download_card_item_title mt10'>{item.name}</div>
                   </div>
                   )})
              }
            </div>
            <Image className='mobile_software_download_product_img' preview={false} />

            <div className='mobile_software_more_buy_path_title'>更多购买途径</div>

            <div className='mobile_software_more_buy_path_card tc'>
              <div className='mobile_software_more_buy_path_card_item flex_column_center'>
                <Image width={'0.8rem'} height={'0.8rem'} src={buyPathCardItemIcon1} preview={false} />
                <div className='mobile_software_more_buy_path_card_item_title'>剑琅联盟天猫旗舰店</div>
                <div className='mobile_software_more_buy_path_card_item_btn'  onClick={()=>this.jumpToMore()}>去看看？</div>
              </div>
              <div className='mobile_software_more_buy_path_card_item flex_column_center'>
                <Image width={'0.8rem'} height={'0.8rem'} src={buyPathCardItemIcon2} preview={false} />
                <div className='mobile_software_more_buy_path_card_item_title'>咨询客服购买</div>
                <div className='mobile_software_more_buy_path_card_item_btn' onClick={()=>this.handleShowInfoToast(this)}>召唤客服</div>
              </div>
            </div>
          </div>
          <MobileFooter></MobileFooter>
      </div>
    );
  };
}