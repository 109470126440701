import React from "react";
import closeImg from "../../static/images/close.png";
import { message } from "antd";
import floatSuccessIcon from '../../static/images/float_success_icon.png'
import hotLineIcon from '../../static/images/hot_line_icon.png'
import "./leaveInfoToast.css";
import { _ } from "../../common/request";

class LeaveInfoToast extends React.Component{
  constructor(props) {
      super(props);
      this.state = {
          name:'',
          mobile:'',
          preSuccess: false
      };
  }

  componentDidMount() {
  }
  componentWillUnmount = () => {
    this.setState = (state,callback)=>{
      return;
    };
  }
  handleClose(){
    if(this.props.handleShowInfoToast !== undefined){
      this.props.handleShowInfoToast()
    }
  }
  handleClick(){
    if(!this.state.name){
      return message.error("请输入您的姓名");
    }
    if(!this.state.mobile){
      return message.error("请输入您的手机号");
    }
    let reg= /^1\d{10}$/
    if(!reg.test(this.state.mobile)){
      return message.error("请输入正确的手机号");
    }
    this.handleClickSavePhone()
  }
  
  handleClickSavePhone(){
    let num = this.state.mobile.slice(-4);
    let params = {
      leadsSource:11,
      industryType:4,
      customerIntention:1,
      customerName:'客戶'+num + this.state.name,
      shopName:'店铺'+num,
      shopRole:1,
      mobile: this.state.mobile
    }
    _.saveExternalLeads(params).then(res=>{
      if(res.code === '200'){
        this.setState({
          preSuccess: true,
        })
      }
    })
  }
  render(){
      return (
          <div className="leave_info_toast_wrap">
            <div className="leave_info_toast_card">
              <img className="leave_info_toast_close" src={closeImg} alt="" onClick={this.handleClose.bind(this)}></img>
              {!this.state.preSuccess&&<div>
                <div className="leave_info_toast_header">
                  <div className="leave_info_toast_header_title">剑琅管家</div>
                  <div className="leave_info_toast_header_desc ml32">简单·易用·有效果</div>
                </div>
                <div className="leave_info_toast_content">
                  <div className="leave_info_toast_content_left">
                    <div className="leave_info_toast_content_left_item">
                      <div className="content_left_item_dote"></div>
                      <div className="content_left_item_text ml15">年交易额<p className="content_left_item_text_num">1000亿</p></div>
                    </div>
                    <div className="leave_info_toast_content_left_item">
                      <div className="content_left_item_dote"></div>
                      <div className="content_left_item_text ml15">服务<p className="content_left_item_text_num">3000万</p>会员消费者</div>
                    </div>
                    <div className="leave_info_toast_content_left_item">
                      <div className="content_left_item_dote"></div>
                      <div className="content_left_item_text ml15">全国<p className="content_left_item_text_num">2万</p>多家美业门店的选择</div>
                    </div>
                    <div className="leave_info_toast_content_left_item">
                      <div className="content_left_item_dote"></div>
                      <div className="content_left_item_text ml15">为<p className="content_left_item_text_num">15万</p>美业手艺人提供专业服务</div>
                    </div>
                  </div>
                  <div className="leave_info_toast_content_right">
                    <input className="content_right_input" placeholder="请输入您的姓名" onChange={(e)=>{this.setState({name:e.target.value})}}></input>
                    <input className="content_right_input" placeholder="请输入您的手机号码" onChange={(e)=>{this.setState({mobile:e.target.value})}}></input>
                    <div className="content_right_submit_button" onClick={this.handleClick.bind(this)}>确认</div>
                  </div>
                </div>
              </div>
              }
              {this.state.preSuccess&&<div className="leave_info_toast_success">
                <img className="leave_info_toast_success_icon mt70" src={floatSuccessIcon} alt=""></img>
                <div className="leave_info_toast_success_title mt40">预约成功</div>
                <div className="leave_info_toast_success_tip mt18">专家顾问将在10分钟内向您致电</div>
                <div className="leave_info_toast_success_desc mt18">非工作时间可能会延迟，敬请谅解</div>
                <div className="leave_info_toast_content_number_warp mt40">
                  <img className="leave_info_toast_header_icon" src={hotLineIcon} alt=""></img>
                  <div className="leave_info_toast_content_title ml20">剑琅联盟全国热线电话：</div>
                  <div className="leave_info_toast_content_number">021-6331 6098</div>
                </div>
              </div>
              }
            </div>
          </div>
      )
  }
}

export default LeaveInfoToast;