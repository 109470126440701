import React, { Component } from "react";
import { _ } from "../../../../common/request";
import { Popup,Toast } from 'antd-mobile'
import "./thirdpartnar.css";
import floatSuccessIcon from '../../../../static/images/float_success_icon.png'
import mobile_toast_close_icon from "../../../../static/images/mobile_toast_close_icon.png";
import thirdPartnerMobileImg1 from "../../../../static/images/product/third_mobile_partner_bg1.png";
import thirdPartnerMobileImg2 from "../../../../static/images/product/third_mobile_partner_bg2.png";
import thirdPartnerMobileImg3 from "../../../../static/images/product/third_mobile_partner_bg3.png";
import thirdPartnerMobileImg4 from "../../../../static/images/product/third_mobile_partner_bg4.png";
import thirdPartnerMobileImg5 from "../../../../static/images/product/third_mobile_partner_bg5.png";
import thirdPartnerMobileImg6 from "../../../../static/images/product/third_mobile_partner_bg6.png";
import thirdPartnerMobileImg7 from "../../../../static/images/product/third_mobile_partner_bg7.png";
import thirdPartnerMobileImg8 from "../../../../static/images/product/third_mobile_partner_bg8.png";
import thirdPartnerMobileImg9 from "../../../../static/images/product/third_mobile_partner_bg9.png";
import thirdPartnerMobileImg10 from "../../../../static/images/product/third_mobile_partner_bg10.png";
import thirdPartnerMobileImg11 from "../../../../static/images/product/third_mobile_partner_bg11.png";
import thirdPartnerMobileImg12 from "../../../../static/images/product/third_mobile_partner_bg12.png";
import thirdPartnerMobileImg13 from "../../../../static/images/product/third_mobile_partner_bg13.png";
import thirdPartnerMobileImg14 from "../../../../static/images/product/third_mobile_partner_bg14.png";
import thirdPartnerMobileImg15 from "../../../../static/images/product/third_mobile_partner_bg15.png";
import thirdPartnerMobileImg16 from "../../../../static/images/product/third_mobile_partner_bg16.png";
import thirdPartnerMobileImg17 from "../../../../static/images/product/third_mobile_partner_bg17.png";

export default class ThirdPartner extends Component {
    constructor(props){
        super(props);
        this.state = {
          name: "",
          mobile: "",
          shopName: "",
          role: "",
          preSuccess: false,
          formVisible:false,
        }
    }

    handleClick(){
      if(!this.state.name){
        Toast.show({
          content: '请输入您的姓名',
          position: 'top',
        })
        return;
      }
      if(!this.state.mobile){
        Toast.show({
          content: '请输入您的手机号',
          position: 'top',
        })
        return;
      }
      let reg= /^1\d{10}$/
      if(!reg.test(this.state.mobile)){
        Toast.show({
          content: '请输入正确的手机号',
          position: 'top',
        })
        return;
      }
      this.handleClickSavePhone()

    }
    handleSelectChange(e){
      this.setState({
        role: e.target.value
      })
    }
    
    
    handleClickSavePhone(){
      let num = this.state.mobile.slice(-4);
      let params = {
        leadsSource:11,
        industryType:4,
        customerIntention:1,
        customerName:'客戶'+num + this.state.name,
        shopName:'店铺'+this.state.shopName,
        shopRole: this.state.role,
        mobile: this.state.mobile
      }
      _.saveExternalLeads(params).then(res=>{
        if(res.code === '200'){
          this.setState({
            preSuccess: true,
          })
        }
      })
    }
    render() {
        return (
          <div className="thirdpartnar_mobile">
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg1} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg2} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg3} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg4} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg5} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg6} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg7} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg8} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg9} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg10} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg11} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg12} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg13} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg14} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg15} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg16} alt=""></img>
            <img className="mobile_third_partner_img" src={thirdPartnerMobileImg17} alt=""></img>
            <div className="mobile_third_partner_btn_wrap"><div className="mobile_third_partner_btn tc" onClick={()=>{this.setState({formVisible:true})}}>立即获取</div></div>
            <Popup
              visible={this.state.formVisible}
              onMaskClick={() => {
                this.setState({
                  formVisible: false
                })
              }}
              onClose={() => {
                this.setState({
                  formVisible: false
                })
              }}
              bodyStyle={{ height: '40vh' }}
            >
              <div className="mobile_third_partner_popup_card">
                <input className="mobile_free_get_form_input" placeholder="请输入您的姓名" onChange={(e)=>{this.setState({name:e.target.value})}}></input>
                <input className="mobile_free_get_form_input" placeholder="请输入您的手机号"  onChange={(e)=>{this.setState({mobile:e.target.value})}}></input>
                <input className="mobile_free_get_form_input" placeholder="美团/点评线上店铺名称" onChange={(e)=>{this.setState({shopName:e.target.value})}}></input>
                <select className="mobile_free_get_form_input" placeholder="请选择您在店铺的角色" onChange={(e)=>this.handleSelectChange(e)}>
                  <option value="1">老板</option>
                  <option value="2">店长</option>
                  <option value="3">店员</option>
                </select>
                <div className="mobile_free_get_button tc color_white" onClick={this.handleClick.bind(this)}>立即获取</div>
              </div>
            </Popup>
            {this.state.preSuccess&&<div className="mobile_third_partner_success_wrap">
              <div className="mobile_third_partner_success_card">
                <div className="mobile_third_partner_success_title">提交成功</div>
                <img className="mobile_third_partner_success_icon" src={floatSuccessIcon} alt=""></img>
                <div className="mobile_third_partner_success_desc">稍后会电话与您沟通详情～</div>
                <div className="mobile_third_partner_success_close tc" onClick={()=>{this.setState({preSuccess:false})}}>我知道了</div>
                <img className="mobile_third_partner_success_hotline" src={mobile_toast_close_icon} alt="" onClick={()=>{this.setState({preSuccess:false})}}></img>
              </div>
            </div>}
          </div>
        )
    }
}