import React, { Component } from "react";

import Header from "../components/header/header";
// import Swiper from "../components/banner/banner";
// import ArticleItem from "../components/article-item/article-item";
import Download from "../components/download/download";
import Footer from "../components/footer/footer";
import ScrollTop from "../components/scrollTop/scrollTop";
import FloatToast from "../components/float-toast/float-toast";
import LeaveInfoToast from "../components/leaveInfoToast/leaveInfoToast";
// import BannerTop from "../components/banner-top/banner-top";
import { PlusOutlined,LeftOutlined,RightOutlined } from '@ant-design/icons';
import { Carousel  } from 'antd';
import offerPlanIcon1 from '../static/home/offer_plan_1.png'
import offerPlanIcon2 from '../static/home/offer_plan_2.png'
import offerPlanIcon3 from '../static/home/offer_plan_3.png'
import offerPlanIcon4 from '../static/home/offer_plan_4.png'
import offerPlanIcon5 from '../static/home/offer_plan_5.png'
import kernelBusinessImg1 from '../static/home/kernel_business_img1.png'
import kernelBusinessImg2 from '../static/home/kernel_business_img2.png'
import kernelBusinessImg3 from '../static/home/kernel_business_img3.png'
import kernelBusinessImg4 from '../static/home/kernel_business_img4.png'
import basicModuleIcon1 from '../static/home/basic_module_icon1.png'
import basicModuleIcon2 from '../static/home/basic_module_icon2.png'
import basicModuleIcon3 from '../static/home/basic_module_icon3.png'
import basicModuleIcon4 from '../static/home/basic_module_icon4.png'
import basicModuleIcon5 from '../static/home/basic_module_icon5.png'
import basicModuleIcon6 from '../static/home/basic_module_icon6.png'
import basicModuleIcon7 from '../static/home/basic_module_icon7.png'
import basicModuleIcon8 from '../static/home/basic_module_icon8.png'
import basicModuleIcon9 from '../static/home/basic_module_icon9.png'
import basicModuleIcon10 from '../static/home/basic_module_icon10.png'
import basicModuleIcon11 from '../static/home/basic_module_icon11.png'
import basicModuleIcon12 from '../static/home/basic_module_icon12.png'
import basicModuleIcon13 from '../static/home/basic_module_icon13.png'
import basicModuleIcon14 from '../static/home/basic_module_icon14.png'
import basicModuleIcon15 from '../static/home/basic_module_icon15.png'
import basicModuleIcon16 from '../static/home/basic_module_icon16.png'
import basicModuleIcon17 from '../static/home/basic_module_icon17.png'
import basicModuleIcon18 from '../static/home/basic_module_icon18.png'
import kernelAdvantageItemContentImg1 from '../static/home/kernel_advantage_item_content_img1.png'
import kernelAdvantageItemContentImg2 from '../static/home/kernel_advantage_item_content_img2.png'
import kernelAdvantageItemContentImg3 from '../static/home/kernel_advantage_item_content_img3.png'
import solutionItemBg1 from '../static/home/solution_item_bg1.png'
import solutionItemBg2 from '../static/home/solution_item_bg2.png'
import solutionItemBg3 from '../static/home/solution_item_bg3.png'
import solutionItemBg4 from '../static/home/solution_item_bg4.png'
import solutionItemBg5 from '../static/home/solution_item_bg5.png'
import solutionItemBg6 from '../static/home/solution_item_bg6.png'
import solutionItemIcon1 from '../static/home/solution_item_icon1.png'
import solutionItemIcon2 from '../static/home/solution_item_icon2.png'
import solutionItemIcon3 from '../static/home/solution_item_icon3.png'
import solutionItemIcon4 from '../static/home/solution_item_icon4.png'
import solutionItemIcon5 from '../static/home/solution_item_icon5.png'
import solutionItemIcon6 from '../static/home/solution_item_icon6.png'
import shopkeepingSystemContentItemIcon11 from '../static/home/shopkeeping_system_content_item_icon11.png'
import shopkeepingSystemContentItemIcon12 from '../static/home/shopkeeping_system_content_item_icon12.png'
import shopkeepingSystemContentItemIcon13 from '../static/home/shopkeeping_system_content_item_icon13.png'
import shopkeepingSystemContentItemIcon14 from '../static/home/shopkeeping_system_content_item_icon14.png'
import shopkeepingSystemContentItemIcon15 from '../static/home/shopkeeping_system_content_item_icon15.png'
import shopkeepingSystemContentItemIcon21 from '../static/home/shopkeeping_system_content_item_icon21.png'
import shopkeepingSystemContentItemIcon22 from '../static/home/shopkeeping_system_content_item_icon22.png'
import shopkeepingSystemContentItemIcon23 from '../static/home/shopkeeping_system_content_item_icon23.png'
import shopkeepingSystemContentItemIcon24 from '../static/home/shopkeeping_system_content_item_icon24.png'
import shopkeepingSystemContentItemIcon25 from '../static/home/shopkeeping_system_content_item_icon25.png'
import shopkeepingSystemContentItemIcon31 from '../static/home/shopkeeping_system_content_item_icon31.png'
import shopkeepingSystemContentItemIcon32 from '../static/home/shopkeeping_system_content_item_icon32.png'
import shopkeepingSystemContentItemIcon33 from '../static/home/shopkeeping_system_content_item_icon33.png'
import shopkeepingSystemContentItemIcon34 from '../static/home/shopkeeping_system_content_item_icon34.png'
import shopkeepingSystemContentItemIcon41 from '../static/home/shopkeeping_system_content_item_icon41.png'
import shopkeepingSystemContentItemIcon42 from '../static/home/shopkeeping_system_content_item_icon42.png'
import shopkeepingSystemContentItemImg11 from '../static/home/shopkeeping_system_content_item_img11.png'
import shopkeepingSystemContentItemImg12 from '../static/home/shopkeeping_system_content_item_img12.png'
import shopkeepingSystemContentItemImg13 from '../static/home/shopkeeping_system_content_item_img13.png'
import shopkeepingSystemContentItemImg14 from '../static/home/shopkeeping_system_content_item_img14.png'
import shopkeepingSystemContentItemImg15 from '../static/home/shopkeeping_system_content_item_img15.png'
import shopkeepingSystemContentItemImg21 from '../static/home/shopkeeping_system_content_item_img21.png'
import shopkeepingSystemContentItemImg22 from '../static/home/shopkeeping_system_content_item_img22.png'
import shopkeepingSystemContentItemImg23 from '../static/home/shopkeeping_system_content_item_img23.png'
import shopkeepingSystemContentItemImg24 from '../static/home/shopkeeping_system_content_item_img24.png'
import shopkeepingSystemContentItemImg25 from '../static/home/shopkeeping_system_content_item_img25.png'
import shopkeepingSystemContentItemImg31 from '../static/home/shopkeeping_system_content_item_img31.png'
import shopkeepingSystemContentItemImg32 from '../static/home/shopkeeping_system_content_item_img32.png'
import shopkeepingSystemContentItemImg33 from '../static/home/shopkeeping_system_content_item_img33.png'
import shopkeepingSystemContentItemImg34 from '../static/home/shopkeeping_system_content_item_img34.png'
import shopkeepingSystemContentItemImg41 from '../static/home/shopkeeping_system_content_item_img41.png'
import shopkeepingSystemContentItemImg42 from '../static/home/shopkeeping_system_content_item_img42.png'

import ourAdvantageImg from '../static/home/our_advantage_img.png'
import productThumbnailItemImg1 from '../static/home/product_thumbnail_item_img1.png'
import productThumbnailItemImg2 from '../static/home/product_thumbnail_item_img2.png'
import productThumbnailItemImg3 from '../static/home/product_thumbnail_item_img3.png'
import productThumbnailItemImg4 from '../static/home/product_thumbnail_item_img4.png'
import productThumbnailItemImg5 from '../static/home/product_thumbnail_item_img5.png'
import homeBannerTabsItemIcon1 from '../static/home/home_banner_tabs_item_icon1.png'
import homeBannerTabsItemIcon2 from '../static/home/shopkeeping_system_content_item_icon32.png'
import homeBannerTabsItemIcon3 from '../static/home/home_banner_tabs_item_icon3.png'
import homeBannerTabsItemIcon4 from '../static/home/home_banner_tabs_item_icon4.png'
import homeBannerTabsItemImg1 from '../static/home/home_banner_tabs_item_img1.png'
import homeBannerTabsItemImg2 from '../static/home/home_banner_tabs_item_img2.png'
import homeBannerTabsItemImg3 from '../static/home/home_banner_tabs_item_img3.png'
import homeBannerTabsItemImg4 from '../static/home/home_banner_tabs_item_img4.png'
import customerImg from "../static/customer/customer_success_img.png"
import customerImg2 from "../static/customer/customer_success_img2.png"
import customerImg3 from "../static/customer/customer_success_img3.png"

import "../static/css/home.css";
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.carouselEL= React.createRef();
    this.state = {
      page: 1,
      pageSize: 5,
      showMore: true,
      bannerTabsList:[
        {label:'预约',icon:homeBannerTabsItemIcon1,img: homeBannerTabsItemImg1,},
        {label:'店务管理（一体机）',icon:homeBannerTabsItemIcon2,img: homeBannerTabsItemImg2,},
        {label:'营销/报表',icon:homeBannerTabsItemIcon3,img: homeBannerTabsItemImg3,},
        {label:'收银台开单',icon:homeBannerTabsItemIcon4,img: homeBannerTabsItemImg4,},
      ],
      currentBannerIndex: 0,
      offerPlanList:[
        {title:'便捷化操作流程',desc:"简化员工软件操作提升效率专注服务",icon: offerPlanIcon1},
        {title:'智能化店务管理',desc:"人财事物全面管理智能分析支持决策",icon: offerPlanIcon2},
        {title:'一体化经营管理',desc:"连锁门店统一管理适配多种经营模式",icon: offerPlanIcon3},
        {title:'精细化客户运营',desc:"轻松建档洞悉客户千人千面个性营销",icon: offerPlanIcon4},
        {title:'多样化营销拓客',desc:"线上线下全面打通海量营销管理工具",icon: offerPlanIcon5}
      ],
      businessList:[
        {title:'开单记账',desc:"开单方便快捷，流程简洁开单方便",remark:'在线开单让我们的效率提高了85%，开启了我们无纸化办公的第一步',icon:kernelBusinessImg1},
        {title:'手机开单',desc:"开单方便快捷，流程简洁",remark:'在线开单让我们的效率提高了85%，开启了我们无纸化办公的第一步',icon:kernelBusinessImg2},
        {title:'营销工具',desc:"帮助商家轻松拓客、留存",remark:'优惠券、客户分群等功能帮助门店拓客、裂变、留存，随时查看营销数据',icon:kernelBusinessImg3},
        {title:'数据统计',desc:"将门店数据进行实时统计、分析、对比",remark:'每天节省统计数据的时间，实时进行数据的统计。比人工快速、精准！',icon:kernelBusinessImg4},
      ],
      currentBusinessIndex: 0,
      basicModuleList:[
        {title:'经营分析',icon: basicModuleIcon1},
        {title:'客户管理',icon: basicModuleIcon2},
        {title:'自助收银',icon: basicModuleIcon3},
        {title:'支出管理',icon: basicModuleIcon4},
        {title:'营销短信',icon: basicModuleIcon5},
        {title:'库存管理',icon: basicModuleIcon6},
        {title:'优惠券',icon: basicModuleIcon7},
        {title:'卡管理',icon: basicModuleIcon8},
        {title:'店铺口碑',icon: basicModuleIcon9},
        {title:'作品管理',icon: basicModuleIcon10},
        {title:'物料商城',icon: basicModuleIcon11},
        {title:'价目表',icon: basicModuleIcon12},
        {title:'员工管理',icon: basicModuleIcon13},
        {title:'商城',icon: basicModuleIcon14},
        {title:'消费短信',icon: basicModuleIcon15},
        {title:'门店管理',icon: basicModuleIcon16},
        {title:'员工薪资',icon: basicModuleIcon17},
        {title:'提成设置',icon: basicModuleIcon18},
      ],
      carouselItemList: [
        {imgUrl:customerImg,name:'C·H Hair Salon',shopManagerName:'店长：Cinthia',message:'被剑琅管家slogan吸引“简单·易用·有效果”，使用后确实体会到了系统操作的便利性和专业性。帮我在各个方面都提升了很多，会员与客情管理都让我非常惊喜，使用后再也；不怕客户流失了，还能帮我门店全面分析运营数据报表，真的节省了很多时间和精力。'},
        {imgUrl:customerImg2,name:'恋恋美甲美睫沙龙',shopManagerName:'经理：小蕊',message:'我们店铺借助剑琅管家SaaS系统中的营销工具抓住了附近绝大部分的客源，包括老带新、线上商城等渠道，帮我们完全打通线上与线下，营业额在短短一个月里就达到了翻倍。而且我能看出剑琅管家的售后客服团队也非常专业，每次系统更新迭代，就会联系我们讲解如何使用新功能，真的非常贴心。'},
        {imgUrl:customerImg3,name:'CARE U Beauty皮肤管理中心',shopManagerName:'总负责人：Sean',message:'剑琅管家丰富的店务管理功能+灵活的卡项玩法+实用多样的营销工具+专业的售后服务，给我们美容院提供了有力支持，让我们能更好地落实员工的奖罚机制，运维好客户，懂得怎么做好营销活动。客户满意才是我们的宗旨，希望未来和剑琅管家一起发展得越来越好！'},
      ],
      kernelAdvantageList: [
        {title:'简单',desc:['极简流程，页面交互既美观又简约'],icon: kernelAdvantageItemContentImg1},
        {title:'易用',desc:['无需人工培训，即可上手操作，功能齐全不繁琐'],icon: kernelAdvantageItemContentImg2},
        {title:'有效果',desc:['深耕美业30余年，有专业研发团队','帮助美业商家轻松经营、高效管理','随时随地了解门店流水，','数据同步，安全可靠，永不丢失',],icon: kernelAdvantageItemContentImg3},
      ],
      currentKernelAdvantageIndex:0,
      solutionList: [
        {title:'美发',icon: solutionItemIcon1,backgroundImg: solutionItemBg1},
        {title:'美甲美瞳',icon: solutionItemIcon2,backgroundImg: solutionItemBg2},
        {title:'养发馆',icon: solutionItemIcon3,backgroundImg: solutionItemBg3},
        {title:'皮肤管理',icon: solutionItemIcon4,backgroundImg: solutionItemBg4},
        {title:'足浴',icon: solutionItemIcon5,backgroundImg: solutionItemBg5},
        {title:'美容SPA',icon: solutionItemIcon6,backgroundImg: solutionItemBg6},
      ],
      currentSolutionIndex: 0,
      shopkeepingSystemList: [
        {
          title:'APP',
          itemArray:[
            {title:'开单记账',infoImg:shopkeepingSystemContentItemImg11,icon:shopkeepingSystemContentItemIcon11},
            {title:'客户预约',infoImg:shopkeepingSystemContentItemImg12,icon:shopkeepingSystemContentItemIcon12},
            {title:'流水订单',infoImg:shopkeepingSystemContentItemImg13,icon:shopkeepingSystemContentItemIcon13},
            {title:'会员管理',infoImg:shopkeepingSystemContentItemImg14,icon:shopkeepingSystemContentItemIcon14},
            {title:'短信中心',infoImg:shopkeepingSystemContentItemImg15,icon:shopkeepingSystemContentItemIcon15},
          ]
        },
        {title:'一体机',
        itemArray:[
          {title:'收银台',infoImg:shopkeepingSystemContentItemImg21,icon:shopkeepingSystemContentItemIcon21},
          {title:'客户预约',infoImg:shopkeepingSystemContentItemImg22,icon:shopkeepingSystemContentItemIcon22},
          {title:'流水订单',infoImg:shopkeepingSystemContentItemImg23,icon:shopkeepingSystemContentItemIcon23},
          {title:'会员管理',infoImg:shopkeepingSystemContentItemImg24,icon:shopkeepingSystemContentItemIcon24},
          {title:'短信中心',infoImg:shopkeepingSystemContentItemImg25,icon:shopkeepingSystemContentItemIcon25},
        ]},
        {title:'小程序',
        itemArray:[
          {title:'商城首页',infoImg:shopkeepingSystemContentItemImg31,icon: shopkeepingSystemContentItemIcon31},
          {title:'自助收银',infoImg:shopkeepingSystemContentItemImg32,icon: shopkeepingSystemContentItemIcon32},
          {title:'预约中心',infoImg:shopkeepingSystemContentItemImg33,icon: shopkeepingSystemContentItemIcon33},
          {title:'个人中心',infoImg:shopkeepingSystemContentItemImg34,icon: shopkeepingSystemContentItemIcon34},
        ]},
        {title:'公众号',
        itemArray:[
          {title:'消息推送',infoImg:shopkeepingSystemContentItemImg41,icon:shopkeepingSystemContentItemIcon41},
          {title:'行业资讯',infoImg:shopkeepingSystemContentItemImg42,icon:shopkeepingSystemContentItemIcon42},
        ]},
      ],
      currentShopkeepingIndex: 0,
      currentShopkeepingItemIndex: 0,
      ourAdvantageList: [
        {title:'强大的技术实力与售后保障',desc:"强大的技术实力与售后保障专业的客户成功团队专业的客户成功团队",icon:'../static/images/close.png'},
        {title:'专业的客户成功团队',desc:"专业的客户成功团队专业的客户成功团队",icon:'../static/images/close.png'},
        {title:'平台代运营和其他增值服务',desc:"专业的客户成功团队，专业的客户成功团队专业的客户成功团队专业的客户成功团队专业的客户成功团队",icon:'../static/images/close.png'},
      ],
      advantageImgUrl:  ourAdvantageImg,
      productThumbnailList: [
        {title:'IPAD平板',icon: productThumbnailItemImg1,desc:'首页 文案文案文案文案',url:''},
        {title:'一体机',icon: productThumbnailItemImg2,desc:'资金账户 文案文案文案文案',url:''},
        {title:'小程序',icon: productThumbnailItemImg3,desc:'经营数据合集 文案文案文案文案',url:''},
        {title:'软件功能',icon: productThumbnailItemImg4,desc:'经营数据一览 文案文案文案文案',url:''},
        {title:'活动',icon: productThumbnailItemImg5,desc:'店铺算收益 文案文案文案文案',url:''},
      ],
      currentProductThumbnailIndex: 0,
      isScrollHere: false,
      showInfoToastVisible:false,
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    if(localStorage.getItem('fromOtherPage')&&JSON.parse(localStorage.getItem('fromOtherPage')).visible){
      this.positionSystem()
    }
  }
  componentWillUnmount() {
    this.setState = () => false
    window.removeEventListener('scroll', this.handleScroll);
    localStorage.removeItem('fromOtherPage')
 }
  handleScroll=()=>{        //滚动条高度
    let scrollTop  = document.documentElement.scrollTop;  //滚动条滚动高度
    let style;
    let selector = document.querySelector(".common_choice_ref");
    if(selector){
      style = window.getComputedStyle(selector);
    }

    this.setState({
      isScrollHere: scrollTop >  parseInt(style?.getPropertyValue("height"))
    })
  }

  // 点击bannertab
  handleBannerTabs(index) {
    this.setState({
      currentBannerIndex: index,
    });
  }

  // 核心业务
  handleBusinessTabs(index) {
    this.setState({
      currentBusinessIndex: index,
    });
  }

  // 核心优势
  handleKernelAdvantageTabs(index){
    this.setState({
      currentKernelAdvantageIndex: index,
    });
  }

  // 解决方案
  handleSolution(index){
    this.setState({
      currentSolutionIndex: index,
    });
  }

  // 店务管理系统
  handleShopkeepingIndex(index){
    this.setState({
      currentShopkeepingIndex: index,
      currentShopkeepingItemIndex: 0
    });
  }
  handleShopkeepingItemIndex(index){
    this.setState({
      currentShopkeepingItemIndex: index,
    });
  }

  // 产品缩略图
  handleProductThumbnail(index){
    this.setState({
      currentProductThumbnailIndex: index,
    });
  }


  // 跳转下载页面
  goSoftware() {
    this.props.history.push({ pathname: "/software" });
    window.scrollTo({
      top: 0, 
      behavior: 'auto',
    });
  }
  //立即了解 
  jumpToProduct(){
    this.props.history.push({ pathname: "/product",state:{index:0}});
    window.scrollTo({
      top: 0, 
      behavior: 'auto',
    });
  }
  handleShowInfoToast() {
    let showInfoToastVisible = !this.state.showInfoToastVisible
    this.setState({
      showInfoToastVisible
    })
  }

  // 定位到剑琅管家店务管理
  positionSystem(){
    let value = JSON.parse(localStorage.getItem('fromOtherPage')).value*1;
    
    let style2;
    let selectorSystem = document.querySelector(".shop_keep_system_ref");
    if(selectorSystem){
      style2 = window.getComputedStyle(selectorSystem)
    }
    let top = parseInt(style2?.getPropertyValue("height"))+100
    window.scrollTo({
      top,
      behavior: "smooth",
    });
     this.handleShopkeepingIndex(value)
  }
  render() {
    let {
      bannerTabsList,
      currentBannerIndex,
      offerPlanList,
      businessList,
      currentBusinessIndex,
      basicModuleList,
      carouselItemList,
      kernelAdvantageList,
      currentKernelAdvantageIndex,
      solutionList,currentSolutionIndex,
      shopkeepingSystemList,
      currentShopkeepingIndex,
      currentShopkeepingItemIndex,
      ourAdvantageList,
      productThumbnailList,
      currentProductThumbnailIndex,
      advantageImgUrl,
      isScrollHere,
      showInfoToastVisible,
    } = this.state;
    return (
      <div className="page">
        <Header index={0} history={this.props.history}> </Header>
        { showInfoToastVisible&&<LeaveInfoToast handleShowInfoToast={this.handleShowInfoToast.bind(this)}></LeaveInfoToast>}
        <FloatToast></FloatToast>
        <div>
          <div className="shop_keep_system_ref">
            <div className="common_choice_ref">
              {/* banner */}
              <div className="banner flex_column_center">
                <div className="title color_white f60 fb mt88">10000+美业门店<span>(自2019年起)</span></div>
                <div className="color_white f25 mt20" style={{letterSpacing:"4px"}}>都在使用的会员管理系统</div>
                <div className="home_download_btn f24 mt40 color_05cf tc" onClick={() => this.goSoftware()}>立即下载</div>
                <div className="home_banner_tabs mt40 f20 flex_absolute_center color_white">
                  {
                    bannerTabsList.map((item,index)=>{
                      return <div className={`home_banner_tabs_item flex_absolute_center ${index===currentBannerIndex?'home_banner_tabs_item_active':''}`}  onMouseEnter={this.handleBannerTabs.bind(this, index)} key={index}>
                        <img className="home_banner_tabs_item_icon mr10" src={item.icon} alt="" />
                        <div className="home_banner_tabs_item_label">{item.label}</div>
                      </div>
                      })
                  }
                </div>
                <img className="home_banner_tabs_item_img mt10" src={bannerTabsList[currentBannerIndex].img} alt="" />
              </div>

              {/* 为您提供解决方案，专业解决美业行业典型问题 */}
              <div className="offer_plan_wrap flex_column_center pt60">
                <div className="plan_top">
                  <div className="color_black  f30 ml15">为您提供解决方案，专业解决美业行业典型问题</div>
                  <div className="flex_between mt60">
                    {
                      offerPlanList.map((item,index)=>{
                        return  <div className="plan_top_content_item flex_column_center" key={index}>
                          <img className="item_icon" src={item.icon} alt="" />
                          <div className="item_title f30">{item.title}</div>
                          <div className="item_desc f25 color_3d3d fw400">{item.desc}</div>
                        </div>
                      })
                    }
                  </div>
                </div>
                <div className="plan_top_bottom"></div>
                <div className="plan_steps mt40 flex_absolute_center color_3d3d f34 tc">
                  <div>
                    <div>剑琅美业</div>
                    <div>SaaS产品</div>
                  </div>
                  <PlusOutlined className="plus_icon" />
                  <div>
                    <div>美业</div>
                    <div>代运营服务</div>
                  </div>
                  <PlusOutlined className="plus_icon" />
                  <div>
                    <div>经营管理</div>
                    <div>解决方案</div>
                  </div>
                  <PlusOutlined className="plus_icon" />
                  <div>
                    <div>美业全域</div>
                    <div>引流获客</div>
                  </div>
                  <PlusOutlined className="plus_icon" />
                  <div>
                    <div>美业</div>
                    <div>生态链</div>
                  </div>
                </div>
              </div>

              {/* 以我专业 让您优越 */}
              {/* <div className="major_and_dominance flex_column_center color_black">
                <div className="f40 mt50">以我专业 让您优越</div>
                <div className="fw400 f30 mt20">30多年IT+美业经验，专业核心研发团队</div>
                <div className="major_and_dominance_info flex_absolute_center tc f50 mt50">
                  <div className="major_and_dominance_info_item">
                    <div>5万+</div>
                    <div className="f30">为5万美业手艺人提供专业服务</div>
                  </div>
                  <div className="major_and_dominance_info_item">
                    <div>1万+</div>
                    <div className="f30">全国1万多家美业门店的选择</div>
                  </div>
                  <div className="major_and_dominance_info_item">
                    <div>300万+</div>
                    <div className="f30">服务300万会员消费者</div>
                  </div>
                  <div className="major_and_dominance_info_item">
                    <div>50亿</div>
                    <div className="f30">年交易额50亿</div>
                  </div>
                </div>
              </div> */}

              {/* 剑琅核心业务 */}
              <div className="kernel_business color_black flex_column_center color_black">
                <div className="kernel_business_title f40">剑琅核心业务</div>
                <div className="business_list flex_absolute_center mt60">
                  {
                    businessList.map((item,index)=>{
                      return <div className={`business_item flex_column_center ${index===currentBusinessIndex?'business_item_active':''}`} key={index} onMouseEnter={this.handleBusinessTabs.bind(this, index)}>
                        <div className="mt44 fb f30">{item.title}</div>
                        <div className="fw400 f14 mt22">{item.desc}</div>
                        <div className="mt67" style={{lineHeight:"30px"}}>
                        {
                          currentBusinessIndex===index?<div className="flex_between tc f20">
                            <div className="business_item_btn" style={{color:"#ffffff",background:'linear-gradient( 90deg, #4588FF 0%, #206EF7 100%)'}} onClick={()=>this.handleShowInfoToast()}>免费试用</div>
                            <div className="business_item_btn ml23" style={{color:"#005cff",background:'#FFFFFF'}} onClick={() => this.goSoftware()}>立即下载</div>
                          </div>
                          :
                          <div className="business_item_remark fb f17 tl">
                            <div className="quotation_mark_top f30">“</div>
                            {item.remark}
                            <div className="quotation_mark_bottom f30">”</div>
                          </div>
                        }
                        </div>
                        <div className={`business_item_img_border flex_absolute_center mt40 ${index === currentBusinessIndex?'business_item_img_border_active':''}`}>
                          <img className="business_item_img" style={{boxShadow:index === currentBusinessIndex?'':'0px 4px 15px 0px rgba(6,39,108,0.1)',border:index===currentBusinessIndex?'':'1px solid rgba(196,122,55,0.1)'}} src={item.icon} alt="" />
                        </div>
                      </div>
                    })
                  }
                </div>
              </div>

              {/* 常见基础模块 */}
              <div className="common_basic_module color_black flex_column_center">
                <div className="common_basic_module_title f40 mt60">常见基础模块</div>
                <div className="common_basic_module_desc fw400 f30 mt30">丰富好玩的营销工具，多种卡项权益，新潮的互动游戏，全面赋能营销获客</div>
                {/* <div className="common_basic_module_more fw400 f20 mt30">查看更多功能 ></div> */}
                <div className="basic_modules mt40">
                  {
                    basicModuleList.map((item,index)=>{
                      return (
                        <div className="basic_module" key={index}>
                          <img className="basic_module_img" src={item.icon} alt="" />
                          <div className="basic_module_title color_333 f16 mt30">{item.title}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>

              {/* 我们客户这样说 */}
              <div className="customer_evaluates flex_column_center mt50">
                <div className="customer_evaluates_title fb f40 mt60">我们的客户都这样说...</div>
                <div className="customer_evaluates_content mt50">
                  <div className="carousel_switch_left flex_absolute_center" onClick={() => {this.carouselEL.current.prev()}} ><LeftOutlined style={{fontSize: '20px',color: '#fff'}} /></div>
                  <div className="carousel_switch_right flex_absolute_center" onClick={() => {this.carouselEL.current.next()}} ><RightOutlined style={{fontSize: '20px',color: '#fff'}} /></div>
                  <Carousel infinite autoplay ref={this.carouselEL}>
                    {
                      carouselItemList.map((item, index)=>{
                        return <div className="carousel_item" style={{display: 'flex'}} key={index}>
                          <div className="flex_absolute_center" style={{height:'100%'}}>
                            <div  className="carousel_item_left flex_column_center">
                              <img className="carousel_item_left_img" src={item.imgUrl} alt="" />
                            </div>
                            <div className="carousel_item_right">
                              <div className="carousel_item_right_top mt30">
                                <div className="carousel_item_right_dot"></div>
                                <div className="carousel_item_right_top_title f30">{item.shopManagerName}</div>
                              </div>
                              <div className="carousel_item_right_bottom mt30">
                                <div className="carousel_item_right_dot"></div>
                                <div className="carousel_item_right_bottom_content f24">{item.message}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      })
                    }
                  </Carousel>
                </div>
              </div>
            </div>
            {/* 头部用户的共同选择 */}
            <div className="user_common_choice">
              <div className="common_choice_title pt70 f40 fb">头部用户的共同选择</div>
              <div className="common_choice_desc flex">
                <div className="common_choice_desc_item flex" style={{marginLeft:"222px"}}>
                  <div className="common_choice_desc_item_title f60 fb">1万+</div>
                  <div className="common_choice_desc_item_name f32 ml20">门店(自2019年起)</div>
                </div>
                <div className="common_choice_desc_item flex ml80">
                  <div className="common_choice_desc_item_title f60 fb">3+</div>
                  <div className="common_choice_desc_item_name f32 ml20">相关行业</div>
                </div>
                <div className="common_choice_desc_item flex ml80">
                  <div className="common_choice_desc_item_title f60 fb">108+</div>
                  <div className="common_choice_desc_item_name f32 ml20">覆盖城市</div>
                </div>
              </div>
              <div className="user_common_choice_img_wrap mt40">
                <div className={`common_choice_img user_common_choice_img1 ${isScrollHere ? 'imgAnimation1':''} f28`}><div className="pl50 mt22">新经典</div></div>
                <div className={`common_choice_img user_common_choice_img2 ${isScrollHere ? 'imgAnimation2':''} f56`}><div className="pl140 mt32">克丽缇娜</div></div>
                <div className={`common_choice_img user_common_choice_img3 ${isScrollHere ? 'imgAnimation3':''} f22`}><div className="pl255 mt20">蜜奢美甲美睫</div></div>
                <div className={`common_choice_img user_common_choice_img4 ${isScrollHere ? 'imgAnimation4':''} f36`}><div className="pl40 mt20">世田造型</div></div>
                <div className={`common_choice_img user_common_choice_img5 ${isScrollHere ? 'imgAnimation5':''} f40`}><div className="pl35 mt22">名流工作室</div></div>
                <div className={`common_choice_img user_common_choice_img6 ${isScrollHere ? 'imgAnimation6':''} f22`}><div className="pl240 mt22">京田美容美甲</div></div>
                <div className={`common_choice_img user_common_choice_img7 ${isScrollHere ? 'imgAnimation7':''} f40`}><div className="pl438 mt22">安迪美业</div></div>
                <div className={`common_choice_img user_common_choice_img8 ${isScrollHere ? 'imgAnimation8':''} f28`}><div className="pl35 mt105">东方名意</div></div>
                <div className={`common_choice_img user_common_choice_img9 ${isScrollHere ? 'imgAnimation9':''} f56`}><div className="pl45 mt174">丽妍雅集</div></div>
                <div className={`common_choice_img user_common_choice_img10 ${isScrollHere ? 'imgAnimation10':''} f29`}><div className="pl355 mt105">新星造型</div></div>
              </div>
            </div>

            {/* 免费试用7天 */}
            <div className="advertising_wrap">
              {/* <div className="advertising_desc f35 color_white">
                免费试用 <span className="f55">7</span> 天 + 一站式解决美业门店经营管理需求
              </div> */}
              <div className="advertising_btn tc f24" onClick={()=>this.jumpToProduct()}>立即了解</div>
            </div>

            {/* 三大核心优势 赋能美业商家 */}
            <div className="kernel_advantage">
              <div className="kernel_advantage_title ml222 f40 color_black mt80">三大核心优势 赋能美业商家</div>
              <div className="kernel_advantage_desc f26 fw400 ml222 mt40">我们重视每一个细节，我们相信追求极致的用户体验才是是对用户的负责。我们致力于成为您真正的移动管理专家</div>
              <div className="kernel_advantage_content flex mt50">
                  {
                    kernelAdvantageList.map((item, index) => {
                      return (<div className={`kernel_advantage_item flex ${currentKernelAdvantageIndex===index?'kernel_advantage_item_active':''}`} key={index} onMouseEnter={this.handleKernelAdvantageTabs.bind(this, index)}>
                        <div className={`kernel_advantage_item_header color_white f32 fw400 ${currentKernelAdvantageIndex===index?'kernel_advantage_item_header_active':''}`}>
                          <div className="kernel_advantage_item_header_index">{`0${index+1}`}</div>
                        </div>
                        <div className={`kernel_advantage_item_content ${currentKernelAdvantageIndex===index?'kernel_advantage_item_content_active':''}`}>
                          <div className="kernel_advantage_item_content_title f32"><div className="kernel_advantage_item_content_title_icon" style={{opacity: currentKernelAdvantageIndex===index?'1':'0.1' }}></div>{item.title}</div>
                          <div className="kernel_advantage_item_content_list">
                            {
                              item.desc.map((item1, index1) => {
                                return (
                                  <div className="kernel_advantage_item_content_list_item f20 ml30 mt20" key={index1}>
                                    <div className="kernel_advantage_item_content_list_item_icon" style={{opacity: currentKernelAdvantageIndex===index?'1':'0.1' }}></div>
                                    <div className="kernel_advantage_item_content_list_item_info ml10">{item1}</div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                        <img className="kernel_advantage_item_content_img" style={{opacity: currentKernelAdvantageIndex===index?'1':'0.1' }} src={item.icon} alt=""/>
                      </div>)
                    })
                  }
              </div>
            </div>

            {/* 解决方案 */}
            <div className="solution">
              <div className="solution_title ml222 mt65 f40 color_black">各行各业都有解决方案</div>
              <div className="solution_desc ml222 f28 mt40 color_333">专注美业信息化服务，多业态门店全适用</div>
              <div className="solution_content mt60 flex_between">
                  {
                    solutionList.map((item, index) => {
                      return (
                        <div className={`solution_item ${currentSolutionIndex===index?'solution_item_active':''}`} key={index} onMouseEnter={this.handleSolution.bind(this, index)}>
                          <img className="solution_item_bg" src={item.backgroundImg} alt=""/>
                          <div className={`solution_item_bg_mark ${currentSolutionIndex===index?'flex_absolute_center solution_item_bg_mark_active':'flex_column_center'}`}>
                            <img className={`solution_item_icon ${currentSolutionIndex===index?'':'mt114'}`} src={item.icon} alt=""/>
                            <div className={`solution_item_title f32 color_white ${currentSolutionIndex===index?'ml50':'mt150'}`}>{item.title}</div>    
                          </div>
                        </div>
                        )
                      })
                  }
              </div>
            </div>
          </div>
          {/* 剑琅管家店务管理系统 */}
          <div className="shopkeeping_system flex_column_center mt110">
            <div className="shopkeeping_system_title f40">剑琅管家店务管理系统</div>
            <div className="shopkeeping_system_list flex_column_center mt80">
              <div className="shopkeeping_system_tabs flex_absolute_center">
                {
                  shopkeepingSystemList.map((item,index)=>{
                    return <div className="shopkeeping_system_tab ml100 mr100" key={index} onMouseEnter={this.handleShopkeepingIndex.bind(this,index)}>
                      {/* <img className="shopkeeping_system_tab_icon" src={item.icon} alt="" /> */}
                      <div className={`shopkeeping_system_tab_title f30 fw400 tc ${index === currentShopkeepingIndex ? "f40 fb" : ""}`}>{item.title}</div>
                      <div className="shopkeeping_system_tab_line" style={{width:currentShopkeepingIndex === index ? '186px':'0px'}}></div>
                    </div>
                  })
                }
              </div>
              <div className="shopkeeping_system_content flex_absolute_center">
                <div className="shopkeeping_system_content_left flex_column_center">
                  {
                    shopkeepingSystemList[currentShopkeepingIndex].itemArray.map((item,index)=>{
                      return <div className={`shopkeeping_system_content_item ${index===currentShopkeepingItemIndex?'shopkeeping_system_content_item_active':''}`} key={index} onMouseEnter={this.handleShopkeepingItemIndex.bind(this,index)}>
                        <img className="shopkeeping_system_content_item_icon ml32" src={item.icon} alt="" />
                        <div className="shopkeeping_system_content_item_name color_black fw400 f30 ml40">{item.title}</div>
                      </div>
                    })
                  }
                </div>
                <div className="shopkeeping_system_content_right ml120">
                  <img className="shopkeeping_system_content_right_img" src={shopkeepingSystemList[currentShopkeepingIndex].itemArray[currentShopkeepingItemIndex].infoImg} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* 我们的优势 */}
          <div className="our_advantage flex_column_center">
            <div className="our_advantage_title fb f40 mt53">我们的优势</div>
            <div className="our_advantage_desc fw400 f30 mt20">为美业商家全方位运营护航</div>
            <div className="our_advantage_content flex_absolute_center mt30">
              <div className="our_advantage_content_left mt8">
                {
                  ourAdvantageList.map((item, index) => {
                    return (
                      <div className="our_advantage_content_left_item color_black mt64" key={index}>
                        <div className="our_advantage_content_left_item_title flex_absolute_center">
                          <div className="our_advantage_content_left_item_dote"></div>
                          <div className="our_advantage_content_left_item_title fw400 f35 ml10">{item.title}</div>
                        </div>
                        <div className="our_advantage_content_left_item_desc fw400 f20 mt20 pl40">{item.desc}</div>
                      </div>
                    )
                 })
                }
              </div>
                <img className="our_advantage_content_right ml120" src={advantageImgUrl} alt=""/>
            </div>
          </div>
          
          {/* 产品图 */}
          <div className="product_thumbnail mt20 mb20 flex_absolute_center">
            {
              productThumbnailList.map((item, index) => {
                return (
                  <div className={`product_thumbnail_item ${currentProductThumbnailIndex === index ? 'color_white product_thumbnail_item_active' : 'color_black'}`} key={index} onMouseEnter={this.handleProductThumbnail.bind(this, index)}>
                    <div className="product_thumbnail_item_title f26 mt10 flex_between">{item.title}
                    {/* {
                      currentProductThumbnailIndex === index ? <div className="product_thumbnail_item_btn tc f20">查看更多</div>:null
                    } */}
                    </div>
                    <img className="product_thumbnail_item_img mt18" src={item.icon} alt="" />
                  </div>
                )
              })
            }
          </div>

        </div>
        {/* <Download history={this.props.history}></Download> */}
        <div className="h20"></div>
        <Footer index={0} history={this.props.history} positionSystem={this.positionSystem.bind(this)}></Footer>
        <ScrollTop></ScrollTop>
      </div>
    );
  }
}
