import React from "react";

import "./description.css";
import yimeiSystemLoadingExpect from '../../../static/images/yimei_system_loading_expect.png'
class description extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="description">
        <img className="yimei_loading_img pb20" src={yimeiSystemLoadingExpect} alt="yimei" />
      </div>
    );
  }
}

export default description;
