import { Component } from 'react';
import MobileHeader from '../../../components/mobileHeader/mobileHeader';
import MobileFooter from '../../../components/mobileFooter/mobileFooter';
import './partner-settlement.css'
import mobilePartnerContentImg1 from '../../../static/images/product/mobile_partner_content_img1.png'
import mobilePartnerContentImg2 from '../../../static/images/product/mobile_partner_content_img2.png'
import mobilePartnerContentImg3 from '../../../static/images/product/mobile_partner_content_img3.png'
import mobilePartnerContentImg4 from '../../../static/images/product/mobile_partner_content_img4.png'
import mobilePartnerContentImg5 from '../../../static/images/product/mobile_partner_content_img5.png'
import mobilePartnerContentImg6 from '../../../static/images/product/mobile_partner_content_img6.png'

export default class AboutMeMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    // let {} = this.state;
    return (
      <div className="page_mobile" style={{background:'#fff'}}>
        <MobileHeader index={3} history={this.props.history}></MobileHeader>
          <img className='mobile_partner_content_img' src={mobilePartnerContentImg1} alt=""/>
          <div className='mobile_partner_content_title'>核心流程</div>
          <div className='mobile_partner_content_desc'>引流-营销-交易-分佣-售后</div>
          <img className='mobile_partner_content_img' src={mobilePartnerContentImg2} alt=""/>
          <img className='mobile_partner_content_img' src={mobilePartnerContentImg3} alt=""/>
          <img className='mobile_partner_content_img' src={mobilePartnerContentImg4} alt=""/>
          <img className='mobile_partner_content_img' src={mobilePartnerContentImg5} alt=""/>
          <img className='mobile_partner_content_img' src={mobilePartnerContentImg6} alt=""/>
        <MobileFooter></MobileFooter>
      </div>
    );
  };
}