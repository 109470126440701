import React from "react";
import { Popover,Input,message } from "antd";
import "./float-toast.css";
import preSaleIcon from "../../static/images/float_pre_service_icon.png";
import preServiceQRCode from "../../static/images/float_pre_service_qr_code.png";
import officialDownloadImg from '../../static/footer/official_accounts_er_code.png'
import consultItemIcon1 from '../../static/images/consult_item_icon1.png'
import consultItemIcon2 from '../../static/images/consult_item_icon2.png'
import consultItemIcon3 from '../../static/images/consult_item_icon3.png'
import floatSuccessIcon from '../../static/images/float_success_icon.png'
import hotLineIcon from '../../static/images/hot_line_icon.png'
import { _ } from "../../common/request";
class FloatToast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      preSuccess: false,
    };
  }

  handleClickSavePhone(){
    let num = this.state.mobile.slice(-4);
    let params = {
      leadsSource:11,
      industryType:4,
      customerIntention:1,
      customerName:'客戶'+num,
      shopName:'店铺'+num,
      shopRole:1,
      mobile: this.state.mobile
    }
    _.saveExternalLeads(params).then(res=>{
      if(res.code === '200'){
        this.setState({
          preSuccess: true,
        })
      }
    })
  }
  handleClick(){
    if(!this.state.mobile){
      return message.error("请输入您的手机号");
    }
    this.handleClickSavePhone()
  }

  // 跳转微信客服
  handleWXservice(){
    window.open('https://work.weixin.qq.com/kfid/kfc7a6c3f08d1c520f0')
  }
  render() {
    let {mobile,preSuccess} = this.state;
    return (
      <div className="float-toast-box flex_column_center mr20">
        <Popover
          content={
            <div className="pre_sale_qr_code">
              <img
                className="pre_sale_qr_code_img"
                src={preServiceQRCode}
                alt=""
              ></img>
            </div>
          }
          placement="left"
        >
          <div className="pre_sales flex_column_center">
            <img
              className="pre_sale_icon mt8"
              src={preSaleIcon}
              alt=""
            ></img>
            <div className="color_white pl20 pr20 lh24">售前咨询</div>
          </div>
        </Popover>
        <div className="consult_wrap mt10">
          <div className="consult_item flex_column_center" style={{borderBottom:'1px solid rgba(0,0,0,0.05)'}} onClick={this.handleWXservice}>
            <img className="consult_item_icon" src={consultItemIcon1} alt=""></img>
            <div className="mt8">微信咨询</div>
          </div>
          <Popover
            content={
              <div className="telephone_consult">
                {
                  preSuccess&&<div className="flex_column_center">
                    <img className="telephone_consult_success" src={floatSuccessIcon} alt=""></img>
                    <div className="telephone_consult_success_title mt8">预约成功</div>
                    <div className="telephone_consult_success_tip mt6">专家顾问将在10分钟内向您致电</div>
                    <div className="telephone_consult_success_desc">非工作时间可能会延迟，敬请谅解</div>
                  </div>
                }
                <div className="telephone_consult_header mt15" style={{justifyContent:preSuccess?"center":''}}>
                  <img className="telephone_consult_header_icon" src={hotLineIcon} alt=""></img>
                  <div className="telephone_consult_header_content ml15">
                    <div className="telephone_consult_header_content_title">剑琅联盟全国热线电话</div>
                    <div className="telephone_consult_header_content_number">021-6331 6098</div>
                  </div>
                </div>
                {!preSuccess&&<Input className="telephone_consult_input"  placeholder="请填写您的手机号" value={mobile} onChange={(e)=>{this.setState({mobile:e.target.value})}}></Input>}
                {!preSuccess&&<div className="telephone_consult_desc tc mt12">专家顾问将在10分钟内向您致电</div>}
                {!preSuccess&&<div className="telephone_consult_button mt15 tc" onClick={this.handleClick.bind(this)}>预约回电</div>}
              </div>
            }
            placement="left"
          >
            <div className="consult_item flex_column_center" style={{borderBottom:'1px solid rgba(0,0,0,0.05)'}}>
              <img className="consult_item_icon" src={consultItemIcon2} alt=""></img>
              <div className="mt8">电话咨询</div>
            </div>
          </Popover>
          <Popover
            content={
              <div className="pre_sale_official_accounts">
                <img
                  className="pre_sale_qr_code_img"
                  src={officialDownloadImg}
                  alt=""
                ></img>
              </div>
            }
            placement="left"
          >
            <div className="consult_item flex_column_center">
              <img className="consult_item_icon" src={consultItemIcon3} alt=""></img>
              <div className="mt8">公众号</div>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}

export default FloatToast;
