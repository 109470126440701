import React, { Component } from "react";

export default class Page304 extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    componentDidMount(){
        let path = window.localStorage.getItem("path");
        let query = window.localStorage.getItem("query");
        let pathArr = path.split("/");
        let newArr = pathArr.filter(item => { return item === "admin"});
        if(newArr.length){
            window.open("https://wxurl.hzdsh169.com/admin?path=" + path  + "&query=" + query ,"_self");
            return ;
        }
        // window.open("https://wxurl.hzdsh169.com/home","_self")
        // window.open("http://192.168.26.68:3005/home","_self")
    }

    render() {
        return (
            <div>
                
            </div>
        )
    }
}