import React, { Component } from "react";
import { Image } from "antd";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import motherOfDragon from "../../../static/images/product/mother_of_dragon.png";
import safeTabItemIcon1 from "../../../static/images/product/safe_tab_item_icon1.png";
import safeTabItemIcon2 from "../../../static/images/product/safe_tab_item_icon2.png";
import safeTabItemIcon3 from "../../../static/images/product/safe_tab_item_icon3.png";
import safeTabItemIcon4 from "../../../static/images/product/safe_tab_item_icon4.png";
import safeTabItemImg1 from "../../../static/images/product/safe_tab_item_img1.png";
import safeTabItemImg2 from "../../../static/images/product/safe_tab_item_img2.png";
import safeTabItemImg3 from "../../../static/images/product/safe_tab_item_img3.png";
import safeTabItemImg4 from "../../../static/images/product/safe_tab_item_img4.png";

import "./safe.css";

export default class Safe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTabIndex:0,
      tabList:[
        { 
          icon:safeTabItemIcon1,
          label:'护航运作稳定',
          desc:'如遇操作中断可获补偿'
        },
        {
          icon:safeTabItemIcon2,
          label:'护航数据安全',
          desc:'安全加密，实时备份'
        },
        {
          icon:safeTabItemIcon3,
          label:'护航商户体验',
          desc:'使用中有效防止广告弹出'
        },
        {
          icon:safeTabItemIcon4,
          label:'护航更新速度',
          desc:'系统实时更新，加载快速'
        },
      ],
      safeModules:[
        {
          title:'为商户运作稳定护航',
          desc:'系统稳定，运作高效，实时展示商家运行状态，如遇运作中断可获相应的补偿。',
          image:safeTabItemImg1
        },
        {
          title:'为商家数据安全护航',
          desc:'数据安全加密，通过实时备份等方式及时保障店铺业务的数据安全。',
          image:safeTabItemImg2
        },
        {
          title:'为你的商户体验护航',
          desc:'店铺上总出现突然弹出的广告? 剑琅全站有效制止广告乱入。',
          image:safeTabItemImg3
        },
        {
          title:'为更新速度护航',
          desc:'系统页面加载速度快，软件免费实时更新',
          image:safeTabItemImg4
        },
      ]
    };
  }
  componentDidMount() {
    this.setState({
      chooseIndex: this.props.currentTabIndex,
    })
  }
  goToServiceState(){
    if(this.props.handleChangeTab !== undefined&&!this.props.isSafe){
      this.props.handleChangeTab(4);
    }else{
      localStorage.setItem('currentTabsInIndex',4)
      this.props.history.push({ pathname: "/product" });
    }
  }

  render() {
    return (
      <div className="safe_wrap">
        {this.state.chooseIndex!==2&&<Header index={6} history={this.props.history}></Header>}
        <div className="safe_header flex_absolute_center">
          <div className="header_left color_white">
            <div className="safe_header_title fb f40">龙妈护航</div>
            <div className="safe_header_desc fw400 f25 mt20">因为专业所以靠谱，为商户们保驾护航</div>
            <div className="safe_header_btn f24 color_05cf tc mt60" onClick={()=>this.goToServiceState(this)}>了解系统运行状态</div>
          </div>
          <div className="header_right">
            <Image preview={false} src={motherOfDragon} width={'100%'} height={'100%'} />
          </div>
        </div>
        <div className="safe_tabs flex_between mt70">
          {
            this.state.tabList.map((item,index)=>{
              return <div className='safe_tab_item flex_column_center' key={index}>
                  <div className="safe_tab_item_icon"><Image preview={false} src={item.icon} width={'100%'} height={'100%'} ></Image></div>
                  <p className="safe_tab_item_label f32 mt12">{item.label}</p>
                  <p className="safe_tab_item_desc f28 fw400 mt12">{item.desc}</p>
                </div>
            })
          }
        </div>
        <div className="safe_modules flex_column_center color_black mt120">
          {
            this.state.safeModules.map((item,index)=>{
              return (
                <div className="safe_module_item" key={index} style={{marginTop:'50px'}}>
                  {
                    index%2===0?
                    (
                      <div className="safe_module_item_card" style={{marginRight: ''}}>
                        <div className="safe_module_item_left">
                           <div className="safe_module_item_title f50">{item.title}</div>
                          <div className="safe_module_item_desc f25 fw400 mt30">{item.desc}</div>
                        </div>
                        <div className="safe_module_item_right" style={{marginRight: index === 2 ?'-100px':''}}>
                          <img alt="" className="safe_module_item_right_image" src={item.image} />
                        </div>
                      </div>
                    )
                    :
                    (
                      <div className="safe_module_item_card" style={{marginBottom:'50px'}}>
                        <div className="safe_module_item_right mr100">
                          <img alt="" src={item.image} style={{width:'340px',height:'276px'}} />
                        </div>
                        <div className="safe_module_item_left ml120">
                           <div className="safe_module_item_title f50">{item.title}</div>
                          <div className="safe_module_item_desc f25 fw400 mt30">{item.desc}</div>
                        </div>
                      </div>
                    )
                  }
                </div>
              )
            })
          }
        </div>
        {this.state.chooseIndex!==2&&<Footer index={6} history={this.props.history}></Footer>}
      </div>
    );
  }
}
