import { Component } from 'react';
import MobileHeader from '../../../components/mobileHeader/mobileHeader';
import MobileFooter from '../../../components/mobileFooter/mobileFooter';
import ReactPlayer from 'react-player'
import './customer-success.css'
import videoUrl from '../../../static/customer/customer_success_video1.mp4'
import customerImg from "../../../static/customer/customer_success_img.png"
import customerImg2 from "../../../static/customer/customer_success_img2.png"
import customerImg3 from "../../../static/customer/customer_success_img3.png"

export default class CustomerSuccessMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contents: [{
        image: customerImg,
        dialogue: '经理 小蕊 说：',
        answer: '“被剑琅管家的slogan所吸引‘简单·易用·有效果’，使用后确实体会到了系统操作的便利性以及它的专业，帮我在各方面都提升了很多，会员与客情管理都让我非常惊喜，使用后再也不怕客户会流失了，还能帮我门店全面分析运营数据报表，真的节省了许多时间和精力。”',
      },{
        image: customerImg2,
        dialogue: '店长 Cinthia 说：',
        answer: '“我们店铺借助剑琅管家SaaS系统中的营销工具抓住了附近绝大部分的客源，包括老带新、线上商城等渠道，帮我们完全打通线上与线下，营业额在短短一个月里就达到了翻倍。而且我能看出剑琅管家的售后客服团队也非常专业，每次系统更新迭代，就会联系我们讲解如何使用新功能，真的非常贴心。”',
      },{
        image: customerImg3,
        dialogue: '总负责人 Sean 说：',
        answer: '“剑琅管家丰富的店务管理功能+灵活的卡项玩法+实用多样的营销工具+专业的售后服务，给我们美容院提供了有力支持，让我们能更好地落实员工的奖罚机制，运维好客户，懂得怎么做好营销活动。客户满意才是我们的宗旨，希望未来和剑琅管家一起发展得越来越好！”',
      }]
    };
  }
  render() {
    return (
      <div className="page_mobile">
        <MobileHeader MobileHeader index={1} history={this.props.history}></MobileHeader>
          <div className="mobile_customer_success_wrap">
            <div className="mobile_success_audio">
              <ReactPlayer
                url={videoUrl}
                playing // 自动播放视频
                loop
                width="100%"
                height="100%"
                style={{ margin: 'auto' }}
              />
            </div>
            <div className="mobile_success_modules flex_column_center color_black">
              {
                this.state.contents.map((item,index)=>{
                    return (
                      <div className="mobile_success_module_item" style={{background:index%2===0?'#F8FAFF':'#FFFFFF'}} key={index}>
                        <div className="mobile_success_module_item_card">
                          <div className="mobile_success_module_item_right"><img src={item.image} alt='' /></div>
                          <div className="mobile_success_module_item_left">
                            <div className="mobile_success_module_item_title flex"> 
                              <div className="mobile_success_module_item_title_icon"></div>{item.dialogue}
                            </div>
                            <div className="mobile_success_module_item_desc flex">
                              <div className="mobile_success_module_item_title_icon"></div>{item.answer}</div>
                          </div>
                        </div>
                  </div>
                  )
                })
              }
            </div>
          </div>
          <MobileFooter></MobileFooter>
      </div>
    );
  };
}