import  React,{ Component } from 'react';
import MobileHeader from '../../../components/mobileHeader/mobileHeader';
import MobileFooter from '../../../components/mobileFooter/mobileFooter';
import MobileInfoToast from '../../../components/mobileInfoToast/mobileInfoToast';
import Version from './versions/versions';
import Safe from './safe/safe';
import Service from './service/service';
import ThirdPartner from './thirdpartnar/thirdpartnar';
import './product.css'
import mobileYiMeiImg from '../../../static/images/product/mobile_yiimei_img.png'
export default class ProductMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageIndex: 0,
      showToast:false,
    };
  }
  componentDidMount() {
    if(this.props.location.state){
      this.handleChangeChildrenTab(this.props.location.state.index)
    }
    if(localStorage.getItem('currentTabsInIndex')){
      this.handleChangeChildrenTab(localStorage.getItem('currentTabsInIndex')*1)
    }
  }
  componentWillUnmount = () => {
    localStorage.removeItem('currentTabsInIndex')
  }
  handleChangeChildrenTab(index){
    this.setState({currentPageIndex:index})
  }
  handleShowInfoToast() {
    let showToast = !this.state.showToast
    this.setState({
      showToast
    })
  }
  render() {
    let {currentPageIndex,showToast} = this.state;
    return (
      <div className="page_mobile">
          <MobileHeader index={2} handleChangeChildrenTab={this.handleChangeChildrenTab.bind(this)} history={this.props.history}></MobileHeader>
          {showToast&&<MobileInfoToast handleShowInfoToast={this.handleShowInfoToast.bind(this)}></MobileInfoToast>}
          {
              currentPageIndex === 0 ? 
              <Version></Version> :
               currentPageIndex ===1 ?
            (<div style={{background:'white'}}>
              <img className='mobile_yi_mei_img' src={mobileYiMeiImg} alt='' />
            </div>)
            :currentPageIndex===2 ? <Safe  handleChangeChildrenTab={this.handleChangeChildrenTab.bind(this)}></Safe>: currentPageIndex===3 ? <ThirdPartner></ThirdPartner> : <Service></Service>
          }
          <MobileFooter></MobileFooter>
      </div>
    );
  };
}