import { Component } from 'react';
import MobileHeader from '../../../components/mobileHeader/mobileHeader';
import MobileFooter from '../../../components/mobileFooter/mobileFooter';
import { Image  } from 'antd';
import about1 from "../../../static/images/about/about1.png";
import about2 from "../../../static/images/about/about2.png";
import about3 from "../../../static/images/about/about3.png";
import about4 from "../../../static/images/about/about4.png";
import about5 from "../../../static/images/about/about5.png";
import talentsImg1 from "../../../static/images/about/talents_img1.png";
import talentsImg2 from "../../../static/images/about/talents_img2.png";
import talentsImg3 from "../../../static/images/about/talents_img3.png";
import talentsImg4 from "../../../static/images/about/talents_img4.png";
import mobileAboutUsHistory from "../../../static/images/about/about_us_content_bg.png"
import './about-us.css'

export default class AboutMeMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experienceList: [{
          image: about2,
          title: '1988年，将电脑工作站系统提供给航天部'
        },{
          image: about3,
          title: '1990年，将首批银行自助服务机ATM带入中国，是中国IT行业的开创者与先驱'
        },{
          image: about4,
          title: '1999年帮助仅有8㎡的丽妍雅集打造成享誉国内外的高端连锁SPA品牌Rubis SPA'
        },{
          image: about5,
          title: '剑琅管家APP，由剑琅联盟创始人Danny潘家辉先生倾力打造'
        }
      ],
      talentsImgList:[talentsImg1,talentsImg2,talentsImg3,talentsImg4],
    }
  }
  render() {
    let {experienceList,talentsImgList} = this.state;
    return (
      <div className="page_mobile">
        <MobileHeader index={4} history={this.props.history}></MobileHeader>
          <div className="mobile_about_us_wrap">
            <Image className="mobile_about_me_introduce_img1" preview={false} src={about1} alt="img"></Image>
            <div className="mobile_about_me_introduce">
              <div className="mobile_about_me_introduce_title">曾任职于4家500强IT公司</div>
              {
                experienceList.map((item,index)=>{
                  return <div className="mobile_about_me_introduce_item flex" key={index}>
                    <Image width={'0.7rem'} height={'0.7rem'} preview={false} src={item.image}></Image>
                    <div className="mobile_about_me_introduce_item_illustrate">{item.title}</div>
                  </div>
               })
              }
            </div>
          </div>
          <div className="mobile_about_us_line"></div>
          <div className='mobile_about_me_banner_warp flex_column_center'>
            <div className='mobile_about_me_banner_title tc'>剑琅联盟 美业人才打造</div>
            <div className='mobile_about_me_banner_desc tc'>剑琅联盟美业人才打造计划，秉持着“为中华民族栽培美业人才，走向国际舞台”的初衷。</div>
            <div className='mobile_about_me_banner_swiper'>
              {
                talentsImgList.map((item, index) => {
                  return (
                      <img  className="about_me_banner_card_item_img" src={item} key={index} alt="img" />
                  )
                })
              }
            </div>
            
          </div>
          <div className="mobile_about_us_line"></div>
          <div className="mobile_about_us_goal_warp flex_absolute_center">
            <div className="mobile_about_us_goal_card flex_column_center">美业获得了诸多成就后，Danny潘家辉先生还是毅然放弃了早年获得加拿大国籍的机会，回到祖国大陆，希望通过他孜孜不倦的社会奉献精神去推动未来中国美业的积极发展，为中华民族的伟大复兴贡献力量！
            2018年，Danny创立剑琅联盟，带领团队研发剑琅管家APP，志在帮助中小型美发门店解决各类痛点。
            <div className='mobile_about_us_goal_title'>剑琅联盟，让美业人更专业、更赚钱！</div>
            </div>
          </div>
          
          <div className="mobile_about_us_line"></div>
          <img className='mobile_about_us_history' src={mobileAboutUsHistory} alt='' />
            <MobileFooter></MobileFooter>
      </div>
    );
  };
}