import React from "react";
import scrollTopImg from "../../static/images/scrollTop.png";
import "./scrollTop.css";

class ScrollTop extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount = () => {
      this.setState = (state,callback)=>{
        return;
      };
  }
      handleScroll=()=>{        //滚动条高度
        let scrollTop  = document.documentElement.scrollTop;  //滚动条滚动高度
        this.setState({
            visible: scrollTop > 100
        })
      }

      handleToTop(){
        window.scrollTo({
          top: 0, 
          behavior: 'smooth',
        });
      }

    render(){
        return (
            this.state.visible&&<div className="scrollTop-box mr20" onClick={this.handleToTop}>
                <img alt="回顶部" src={scrollTopImg}></img>
           </div>
        )
    }
}

export default ScrollTop;