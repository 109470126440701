import React, { Component } from "react";
import defaultImg from "../../static/images/tick-circle.png"
import { Image } from "antd";
import { CheckOutlined } from '@ant-design/icons';
import LeaveInfoToast from "../../components/leaveInfoToast/leaveInfoToast";
import officialLogo from '../../static/images/official_logo.png'
import functionModuleIcon1 from '../../static/images/function_module_icon1.png'
import functionModuleIcon2 from '../../static/images/function_module_icon2.png'
import functionModuleItemIcon1 from '../../static/images/function_module_item_icon1.png'
import functionModuleItemIcon2 from '../../static/images/function_module_item_icon2.png'
import functionModuleItemIcon3 from '../../static/images/function_module_item_icon3.png'
import functionModuleItemIcon4 from '../../static/images/function_module_item_icon4.png'
import functionModuleItemIcon5 from '../../static/images/function_module_item_icon5.png'

import systemModuleIcon1 from '../../static/images/system_module_icon1.png'
import systemModuleIcon2 from '../../static/images/system_module_icon2.png'
import systemModuleIcon3 from '../../static/images/system_module_icon3.png'
import systemModuleIcon4 from '../../static/images/system_module_icon4.png'
import systemModuleIcon5 from '../../static/images/system_module_icon5.png'
import systemModuleIcon6 from '../../static/images/system_module_icon6.png'
import systemModuleIcon7 from '../../static/images/system_module_icon7.png'
import customerImg from '../../static/customer/customer_img.png'
import productModuleIcon1 from "../../static/images/product/product_module_icon1.png";
import productModuleIcon2 from "../../static/images/product/product_module_icon2.png";
import productModuleIcon3 from "../../static/images/product/product_module_icon3.png";
import productModuleIcon4 from "../../static/images/product/product_module_icon4.png";
import productModuleIcon5 from "../../static/images/product/product_module_icon5.png";
import productModuleIcon6 from "../../static/images/product/product_module_icon6.png";
import productModuleImg1 from "../../static/images/product/product_module_img1.png";
import productModuleImg2 from "../../static/images/product/product_module_img2.png";
import productModuleImg3 from "../../static/images/product/product_module_img3.png";
import productModuleImg4 from "../../static/images/product/product_module_img4.png";
import productModuleImg5 from "../../static/images/product/product_module_img5.png";
import productModuleImg6 from "../../static/images/product/product_module_img6.png";
import shopMemberImg1 from "../../static/images/product/shop_member_img1.png";
import shopMemberImg2 from "../../static/images/product/shop_member_img2.png";
import shopMemberImg3 from "../../static/images/product/shop_member_img3.png";
import shopMemberImg4 from "../../static/images/product/shop_member_img4.png";
import shopMemberImg5 from "../../static/images/product/shop_member_img5.png";
import shopMemberImg6 from "../../static/images/product/shop_member_img6.png";
import shopMemberImg7 from "../../static/images/product/shop_member_img7.png";
import shopMemberImg8 from "../../static/images/product/shop_member_img8.png";
import shopMemberImg9 from "../../static/images/product/shop_member_img9.png";
import shopMemberImg10 from "../../static/images/product/shop_member_img10.png";
import shopMemberImg11 from "../../static/images/product/shop_member_img11.png";
import shopMemberImg12 from "../../static/images/product/shop_member_img12.png";
import shopMemberImg13 from "../../static/images/product/shop_member_img13.png";
import shopMemberImg14 from "../../static/images/product/shop_member_img14.png";
import shopMemberImg15 from "../../static/images/product/shop_member_img15.png";
import shopMemberImg16 from "../../static/images/product/shop_member_img16.png";


import "./system.css";
export default class System extends Component {
    constructor(props){
        super(props);
        this.state = {
            title: '剑琅联盟全新定制版',
            tip: '以下版本均包含',
            showInfoToastVisible: false,
            functions: [{
              image: systemModuleIcon1,
              name: '异业合作',
            },{
              image: systemModuleIcon2,
              name: '流水订单',
            },{
              image: systemModuleIcon3,
              name: '库存管理',
            },{
              image: systemModuleIcon4,
              name: '经营分析',
            },{
              image: systemModuleIcon5,
              name: '会员管理',
            },{
              image: systemModuleIcon6,
              name: '自助收银',
            },{
              image: systemModuleIcon7,
              name: '收益提现',
            }],
            hoverBuyIndex: null,
            more: '更多购买途径',
            excellentEdition: {
              title: '卓越版',
              tip: '适用于有基础店务管理需求的单店',
              info: '购买咨询',
              list: [{
                img: defaultImg,
                title: '简单便捷的收银功能'
              },{
                img: defaultImg,
                title: '会员精细化运营'
              },{
                img: defaultImg,
                title: '员工高效管理'
              },{
                img: defaultImg,
                title: 'X个营销工具 满足日常基础营销需求'
              }]
            },
            shopkeeperVersion: {
              title: '掌柜版',
              tip: '适用于有数据分析需求和强营销的单店/连锁店',
              info: '购买咨询',
              buy:'直接购买',
              list: [{
                img: defaultImg,
                title: '智慧收银'
              },{
                img: defaultImg,
                title: '会员精细化运营'
              },{
                img: defaultImg,
                title: '员工高效管理'
              },{
                img: defaultImg,
                title: '经营报表分析'
              },{
                img: defaultImg,
                title: '可视化数据分析支持门店经营决策'
              },{
                img: defaultImg,
                title: 'X个营销工具 满足日常基础营销需求'
              }]
            },
            customizedVersion: {
              title: '定制版',
              tip: '适用于营销需求多样化的高端单店/连锁店/连锁店',
              button: '详询定价',
              list: [{
                img: defaultImg,
                title: '智慧收银'
              },{
                img: defaultImg,
                title: '会员精细化运营'
              },{
                img: defaultImg,
                title: '员工高效管理'
              },{
                img: defaultImg,
                title: '经营报表分析'
              },{
                img: defaultImg,
                title: '可视化数据分析支持门店经营决策'
              },{
                img: defaultImg,
                title: '定制化营销方案强化品牌专属调性'
              },{
                img: defaultImg,
                title: '全部营销工具 满足市场多样化营销需求'
              }]
            },
            // 二阶段
            backgroundObj : {
              // background: `url(${defaultImg}) `,
              // backgroundSize: '100%,100%',
              // backgroundRepeat: 'no-repeat'
            },
            functionTitle: [{
              img: functionModuleIcon1,
              give: '赠送：货架/剪刀包*2'
            }, {
              img: functionModuleIcon2,
              give: '赠送：货架/剪刀包*2'
            }, {
              img: functionModuleIcon1,
              give: '赠送：货架/剪刀包*2'
            }],
            functionArr: [{
              img: functionModuleItemIcon1,
              title: '常用功能',
              list: [{
                name: '开单记账',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '流水订单',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '预约管理',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '收益提现',
                icon1: '-',
                icon2: '',
                icon3: '-',
              },{
                name: '我的薪资',
                icon1: '',
                icon2: '',
                icon3: '',
              }]
            },{
              img: functionModuleItemIcon2,
              title: '经营管理',
              list: [{
                name: '经营分析',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '客户管理',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '自助收银',
                icon1: '-',
                icon2: '',
                icon3: '-',
              },{
                name: '支出管理',
                icon1: '限时赠送',
                icon2: '限时赠送',
                icon3: '限时赠送',
              }]
            },{
              img: functionModuleItemIcon3,
              title: '营销获客',
              list: [{
                name: '营销短信',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '优惠券',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '商城',
                icon1: '-',
                icon2: '限时赠送',
                icon3: '-',
              },{
                name: '作品管理',
                icon1: '-',
                icon2: '',
                icon3: '-',
              }]
            },{
              img: functionModuleItemIcon4,
              title: '店铺管理',
              list: [{
                name: '物料商城',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '价目表',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '卡管理',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '门店管理',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '店铺装修',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '消费短信',
                icon1: '-',
                icon2: '',
                icon3: '-',
              },{
                name: '库存管理',
                icon1: '限时赠送',
                icon2: '限时赠送',
                icon3: '限时赠送',
              }]
            },{
              img: functionModuleItemIcon5,
              title: '员工管理',
              list: [{
                name: '员工管理',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '员工薪资',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '业绩排行',
                icon1: '限时赠送',
                icon2: '限时赠送',
                icon3: '限时赠送',
              },{
                name: '提成设置',
                icon1: '-',
                icon2: '',
                icon3: '-',
              },{
                name: '业绩设置',
                icon1: '',
                icon2: '',
                icon3: '',
              }]
            },{
              img: '',
              title: '其它',
              list: [{
                name: '消息入口',
                icon1: '',
                icon2: '',
                icon3: '',
              },{
                name: '“我的”入口',
                icon1: '',
                icon2: '',
                icon3: '',
              }]
            }],
            versionSelectionGuideList:[
              {icon:customerImg,message:'你好啊！我这边是美容院的，想咨询一下你们家的美业软件。',left:true},
              {icon:officialLogo,message:'您好呀！欢迎光临小店！我们这里有各种版本的美业软件，绝对能满足您的需求。',left:false},
              {icon:customerImg,message:'哈哈，那我得好好了解一下。想问下那个卓越版，是不是适合我这种基础管理需求的门店啊？ ',left:true},
              {icon:officialLogo,message:'没错没错！卓越版就是专门为有基础门店管理需求设计的，操作简便，功能齐全。',left:false},
              {icon:customerImg,message:'没错没错！卓越版就是专门为有基础门店管理需求设计的，操作简便，功能齐全。',left:true},
              {icon:officialLogo,message:'掌柜版嘛，那就更高级了！它更适合有数据分析需求和营销能力的门店。',left:false},
              {icon:officialLogo,message:'您想啊，要是您的美容院生意越来越好，肯定需要更多的数据支持来制定营销策略，对吧？ ',left:false},
              {icon:customerImg,message:'哎呀，你说得对啊！我这美容院最近生意确实越来越好，数据分析确实很重要。不过，我就是怕操作太复杂了，我这把老骨头可经不起折腾啊！ ',left:true},
              {icon:officialLogo,message:'您放心吧！我们的软件都是傻瓜式操作，一学就会。而且我们还有专业的客服售后团队，随时为您解答疑问，保证让您用得顺心，用得放心！ ',left:false},
              {icon:customerImg,message:'听你这么说，我就放心多了。那我就先买个卓越版试试吧，好的话我再升级掌柜版！',left:true},
              {icon:officialLogo,message:'好的，感谢您的信任和支持！',left:false},
            ],
            tabList:[
              {
                label:'门店店务管理',
                value:[
                  {icon:shopMemberImg1,name:'SHIER·拾贰',title:'物料商城将各种物料按类别清晰展示，智能推荐合适的物料，让顾客方便挑选自己喜爱的商品，提高采买效率',modules:[{name:'物料商城',color:'#FFE5CC'},{name:'价目表',color:'#CDE1FD'},],desc:'价目表支持对各种服务项目进行定价，如剪发、染发、护肤等，商家可根据实际情况自由设定价格'},
                  {icon:shopMemberImg2,name:'HAIR SALON 造型',title:'用于管理顾客的美容卡、储蓄卡、会员卡等 帮助门店实现会员制度的数字化管理',modules:[{name:'卡管理',color:'#FFF8C6'},{name:'门店管理',color:'#FFD7ED'},],desc:'管理美业门店的运营提高门店的服务质量和运营效率'},
                  {icon:shopMemberImg3,name:'美格造型 MEIGE',title:'商家可以自定义店铺的外观和布局可以更好的展示品牌形象且更能吸引顾客',modules:[{name:'店铺装修',color:'#CDE1FD'},{name:'消费短信',color:'#FFD7ED'},],desc:'商家在顾客完成消费后能自动发送短信通知客户相关信息 如订单状态、支付结果、优惠信息等 通过短信互动，可以增加顾客粘性'},
                  {icon:shopMemberImg4,name:'lemon',title:'商家与顾客在线签订、管理和存储合同加密保障合同内容的安全性，防止信息泄露和篡改',modules:[{name:'电子合同',color:'#FFE5CC'},{name:'设备管理',color:'#FFF8C6'},],desc:'能帮助商家实现门店设备的全面管理包括设备的采购、入库、使用、维修和报废等环节'}
                ]
              },
              {
                label:'客户营销管理',
                value:[
                  {icon:shopMemberImg5,name:'沐颜',title:'短信中心是软件中一个重要的通信工具与顾客进行高效、精准的短信互动。',modules:[{name:'短信中心',color:'#FFE5CC'},{name:'商城',color:'#CDE1FD'},],desc:'商城功能是一个线上购物模块为顾客提供丰富的商品选择和便捷的购物体验'},
                  {icon:shopMemberImg6,name:'享 YUE SPA',title:'商家（如美发师、美甲师等）可上传自己的作品图片或视频展示给潜在客户，支持作品分类和标签，便于用户查找和浏览',modules:[{name:'作品管理',color:'#FFF8C6'},{name:'店铺口碑',color:'#FFD7ED'},],desc:'允许客户对门店进行评价和打分，展示店铺的服务质量和专业水平'},
                  {icon:shopMemberImg7,name:'轻颜美学',title:'对软件中的顾客进行精细化管理和分类可提高服务的效率',modules:[{name:'客户分群',color:'#CDE1FD'},{name:'第三方核销',color:'#FFD7ED'},],desc:'通过外部核销系统，如微信支付、支付宝、银联等支付平台的核销方式可实现电子优惠券、会员卡等权益的线上验证和核销减少人工操作成本'},
                  {icon:shopMemberImg8,name:'MI造型',title:'商家为了吸引顾客、刺激消费发放的一种电子凭证顾客在消费时可以使用优惠券抵扣部分或全部金额，从而享受优惠',modules:[{name:'商家优惠券',color:'#FFE5CC'},{name:'消费返券',color:'#FFF8C6'},],desc:'顾客在消费后，商家根据消费金额返给顾客不同额度的电子券顾客可以在下次消费时使用这些电子返券抵扣部分金额'}
                ]
              },
              {
                label:'卡服务管理',
                value:[
                  {icon:shopMemberImg9,name:'奈莉',title:'利用数据分析，帮助商家深入了解业务运营状况从而做出更明智的经营决策',modules:[{name:'经营分析',color:'#FFE5CC'},{name:'报表中心',color:'#CDE1FD'},],desc:'灵活易用的数据管理工具，能够帮助门店更好地管理和分析业务数据提升运营效率和管理水平'},
                  {icon:shopMemberImg10,name:'E+ 简艺造型',title:'帮助门店更好地跟踪、理解和满足客户需求，提升客户满意度和忠诚度',modules:[{name:'客户管理',color:'#FFF8C6'},{name:'服务日志',color:'#FFD7ED'},],desc:'记录服务过程中的关键信息，帮助门店提升服务质量和效率'},
                  {icon:shopMemberImg11,name:'缇芙丽养生会所',title:'允许顾客在完成服务后，直接在软件上进行电子签名确认。不仅节省了传统纸质签名的繁琐流程，还提升了数据的实时性和准确性',modules:[{name:'电子签名',color:'#CDE1FD'},{name:'自助收银',color:'#FFD7ED'},],desc:'为顾客提供了便捷、快速的支付体验'},
                  {icon:shopMemberImg12,name:'伊木朵美发沙龙',title:'商家全面掌控各类成本支出，包括员工工资、产品采购、设备维护、租金水电等',modules:[{name:'支出管理',color:'#FFE5CC'},{name:'智能收银',color:'#FFF8C6'},],desc:'实现了快速、精准的收银操作。支持多种支付方式，包括现金、银行卡、移动支付等，满足不同客户的支付需求'}
                ]
              },
              {
                label:'员工端小程序',
                value:[
                  {icon:shopMemberImg13,name:'MUMU沐沐美甲美睫',title:'顾客可以通过小程序轻松预约美容、美发、美甲等一系列美业服务',modules:[{name:'在线预约',color:'#FFE5CC'},{name:'操作简易',color:'#CDE1FD'},],desc:'选择服务类型、时间、技师，托尼等信息，方便快捷'},
                  {icon:shopMemberImg14,name:'瑞丽克丝',title:'通过小程序自助买单，支持微信支付',modules:[{name:'移动支付',color:'#FFF8C6'},{name:'智慧收银',color:'#FFD7ED'},],desc:'让顾客能够随时随地完成支付操作，提高支付效率'},
                  {icon:shopMemberImg15,name:'路易芬尼',title:'会员注册、登录、积分查询、优惠券领取等功能',modules:[{name:'会员中心',color:'#CDE1FD'},{name:'卡券管理',color:'#FFD7ED'},],desc:'方便客户管理自己的会员信息，享受更多会员特权'},
                  {icon:shopMemberImg16,name:'菲梵护肤造型',title:'定期发布各种优惠活动信息，如满减、折扣、赠品等，吸引客户前来消费',modules:[{name:'营销活动',color:'#FFE5CC'},{name:'拉新促活',color:'#FFF8C6'},],desc:'有效提高会员复购率'}
                ]
              }
            ],
            productModules:[
              {
                icon:productModuleIcon1,
                title:'开单记账',
                desc:'全程无纸化开单流程，简单方便告别手工记账错单、漏单等情况',
                list:['极简操作流程','无纸化开单','阿里云数据存储'],
                image:productModuleImg1
              },
              {
                icon:productModuleIcon2,
                title:'会员管理',
                desc:'信息录入快捷，个人档案长久留存，永不丢失，还能为顾客生成标签，做好客户分群，实现精准营销',
                list:['会员信息','消费信息','办卡·储值·折扣等'],
                image:productModuleImg2
              },
              {
                icon:productModuleIcon3,
                title:'店铺业绩（经营报表）',
                desc:'可视化的营业数据报表，简单易懂，为门店经营决策提供数据支持',
                list:['日/周/月营业报表','增长趋势分析','门店业绩报表','员工业绩报表等'],
                image:productModuleImg3
              },
              {
                icon:productModuleIcon4,
                title:'智能预约',
                desc:'客户预约项目、技师、未来事项等实时联动查询，轻松掌握门店现场资源动态，满足各类预约场景',
                list:['极速预约','到店提醒','账单推送'],
                image:productModuleImg4
              },
              {
                icon:productModuleIcon5,
                title:'智慧收银',
                desc:'快速便捷、随扫随付，免去排队等待，开启支付新风尚。经营变现，即时查询，结算明细，一目了然',
                list:['自助买单','收益提现','线上支付'],
                image:productModuleImg5
              },
              {
                icon:productModuleIcon6,
                title:'私域营销（营销获客）',
                desc:'提供拓/留/锁/升多元化营销工具，线上线下全打通，引爆门店客流，快速提升营业额',
                list:['营销短信','线上商城','自定义优惠券/代金券','公域店铺入驻','海报工坊'],
                image:productModuleImg6
              },
            ],
            currentTabIndex:0
        }
    }
    hoverBuyBtn(index){
      this.setState({
        hoverBuyIndex: index
      })
    }
    handleShowInfoToast() {
      let showInfoToastVisible = !this.state.showInfoToastVisible
      this.setState({
        showInfoToastVisible
      })
    }
    tabClick(index){
      this.setState({
        currentTabIndex:index
      })
    }
    render() {
        return (
          <div className="system">
            <div className="description">
               <div className="beauty_industry_description color_white">
                <div className="beauty_industry_description_title fb f40 mt88">垂直美业+深耕美业  覆盖美业经营管理全场景</div>
                <div className="beauty_industry_description_desc fw400 f25 mt30">一站式解决美业门店现有的“提效、管理、业绩增长”等难题助力商家轻松高效管理人、财、物</div>
                <div className="beauty_industry_description_tabs mt65 tc">
                  {
                    this.state.tabList.map((item,index)=>{
                      return(
                        <div className={`beauty_industry_description_tabs_item fw400 f24 mr50 ${this.state.currentTabIndex===index?'beauty_industry_description_tabs_item_active':''}`} onMouseEnter={()=>this.tabClick(index)} key={index}>{item.label}</div>
                      )
                    })
                  }
                </div>
                <div className="beauty_industry_description_tab flex_between mt60 f20 color_black">
                  {
                    this.state.tabList[this.state.currentTabIndex].value.map((item,index)=>{
                      return (
                        <div className="beauty_industry_description_tab_card" key={index}>
                          <div className="card_header ml30 mt30 fw400">
                            <div className="card_header_icon mr12"><Image preview={false} src={item.icon} /></div>
                            {item.name}
                          </div>
                          <div className="card_title ml30 mt15">{item.title}</div>
                          <div className="modules ml30 mt15">
                            {
                              item.modules.map((item1,index1)=>{
                                return(
                                  <div className="module f16 tc mr12" key={index1} style={{backgroundColor:item1.color}}>{item1.name}</div>
                                )
                              })
                            }
                          </div>
                          <div className="card_desc ml30 mt20">{item.desc}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>

              <div className="product_modules flex_column_center color_black">
                {
                  this.state.productModules.map((item,index)=>{
                    return (
                      <div className="product_module_item" style={{background:index%2===0?'#F8FAFF':'#FFFFFF'}} key={index}>
                        {
                          index%2===0?
                          (
                            <div className="product_module_item-card flex_absolute_center">
                              <div className="product_module_item_left">
                                <div className="product_module_item_title f38"> 
                                  <div className="product_module_item_title_icon mr30"><Image preview={false} src={item.icon} alt="" /></div>
                                  <div style={{height:'52px',lineHeight:'65px'}}>{item.title}</div>
                                </div>
                                <div className="product_module_item_desc f24 fw400 mt30">{item.desc}</div>
                                <div className="product_module_item_list f26 mt30">
                                  {
                                    item.list.map((item, index) => {
                                      return <div className="product_module_item_list_item mb12" key={index}>{item}</div>
                                    })
                                  }
                                </div>
                                <div className="product_module_item_buttons f22 mt28 tc">
                                  <div className="product_module_item_button_left" onClick={()=>this.handleShowInfoToast()}>购买咨询</div>
                                </div>
                              </div>
                              <div className="product_module_item_right ml20">
                                <Image preview={false} src={item.image} />
                              </div>
                            </div>
                          )
                          :
                          (
                            <div className="product_module_item-card flex_absolute_center">
                              <div className="product_module_item_right">
                                <Image preview={false} src={item.image} />
                              </div>
                              <div className="product_module_item_left ml20">
                                <div className="product_module_item_title f38"> 
                                  <div className="product_module_item_title_icon mr30"><Image preview={false} src={item.icon} alt="" /></div>
                                  <div style={{height:'52px',lineHeight:'65px'}}>{item.title}</div>
                                </div>
                                <div className="product_module_item_desc f24 fw400 mt30">{item.desc}</div>
                                <div className="product_module_item_list f26 mt30">
                                  {
                                    item.list.map((item, index) => {
                                      return <div className="product_module_item_list_item mb12" key={index}>{item}</div>
                                    })
                                  }
                                </div>
                                <div className="product_module_item_buttons f22 mt28 tc">
                                  <div className="product_module_item_button_left" onClick={()=>this.handleShowInfoToast()}>购买咨询</div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
            {/* 免费试用7天 */}
            <div className="advertising_wrap">
            </div>

            { this.state.showInfoToastVisible&&<LeaveInfoToast handleShowInfoToast={this.handleShowInfoToast.bind(this)}></LeaveInfoToast>}
            <div className="system-content">
              <div className="system-content-one">
                <p className="name">{this.state.title}</p>
                <p className="tip">{this.state.tip}</p>
                <div className="system-function flex ju_bw mb100">
                  {this.state.functions.map((item, index) => {
                    return (
                      <div className="system-function-item" key={index}>
                        <img className="system-function-img" src={item.image} alt="img"></img>
                        <p className="system-function-name">{item.name}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="system-content-introduce flex_absolute_center mt60">
                  <div className="one">
                    <div className="header"></div>
                    <div className="content">
                      <div className="introduce-title mt40 mb30">{this.state.excellentEdition.title}</div>
                      <div className="introduce-tip">{this.state.excellentEdition.tip}</div>
                      <div className="introduce-price"><span>¥</span>?<span>/年</span></div>
                      <div className="introduce-function  mb100">
                        {this.state.excellentEdition.list.map((item, index) => {
                          return (
                            <div className="introduce-item flex" key={index}>
                              <img className="introduce-img" src={item.img} alt="img"></img>
                              <span className="introduce-name">{item.title}</span>
                            </div>
                          );
                        })}
                      </div>
                      <div className="introduce-buttons mt60 flex_absolute_center ju_bw">
                        <div className="introduce-button" onClick={()=>this.handleShowInfoToast()}>{this.state.excellentEdition.info}</div>
                      </div>
                    </div>
                  </div>
                  <div className="two">
                    <div className="header"></div>
                    <div className="content">
                      <div className="introduce-title mt40 mb30">{this.state.shopkeeperVersion.title}</div>
                      <div className="introduce-tip">{this.state.shopkeeperVersion.tip}</div>
                      <div className="introduce-price"><span>¥</span>?<span>/年</span></div>
                      <div className="introduce-equity">已包含 <span>卓越版</span> 所有功能权益</div>
                      <div className="introduce-function">
                        {this.state.shopkeeperVersion.list.map((item, index) => {
                          return (
                            <div className="introduce-item flex" key={index}>
                              <img className="introduce-img" src={item.img} alt="img"></img>
                              <p className="introduce-name">{item.title}</p>
                            </div>
                          );
                        })}
                      </div>
                      <div className="introduce-buttons mt60 flex_absolute_center ju_bw">
                        <div className="introduce-button" onClick={()=>this.handleShowInfoToast()}>{this.state.shopkeeperVersion.info}</div>
                      </div>
                    </div>
                  </div>
                  <div className="three">
                    <div className="header"></div>
                    <div className="content">
                      <div className="introduce-title mt40 mb30">{this.state.customizedVersion.title}</div>
                      <div className="introduce-tip">{this.state.customizedVersion.tip}</div>
                      <div className="introduce-price" onClick={()=>this.handleShowInfoToast()}>{this.state.customizedVersion.button}</div>
                      <div className="introduce-equity">已包含 <span>掌柜版</span> 所有功能权益</div>
                      <div className="introduce-function mb100">
                        {this.state.customizedVersion.list.map((item, index) => {
                          return (
                            <div className="introduce-item flex" key={index}>
                              <img className="introduce-img" src={item.img} alt="img"></img>
                              <p className="introduce-name">{item.title}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="system-content-two">
                <div className="content mt70">
                  <div className="content-header flex ju_bw" >
                    <div className="one tc f36 color_white" style={this.state.backgroundObj}>功能模块</div>
                    {this.state.functionTitle.map((item, index) => {
                      return (
                        <div className="two" key={index}>
                          <img className="two-img" src={item.img} alt="img"></img>
                          <div className="two-price"><span>¥</span>?<span>/年</span></div>
                          <p className="two-give">{item.give}</p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="function_module_table">
                    {
                      this.state.functionArr.map((item, index) => {
                        return (
                          <div key={index}>
                            <div className="function_module_table_title flex mt50 ml25">
                              {item.img&&<div className="function_module_table_title_icon"><Image preview={false} src={item.img} /></div>}
                             <div className="f34 ml20">{item.title}</div> 
                            </div>
                            <div className="function_module_table_body mt20">
                              {
                                item.list.map((item1, index1) => {
                                  return (
                                    <div className="function_module_table_line flex_between tc fw400 f24" style={{background: index1 % 2 === 0 ? '#F4F9FF' : 'white'}} key={index1}>
                                      <div className="function_module_table_line_name color_333">{item1.name}</div>
                                      <div className="function_module_table_line_icon1 flex_absolute_center ">
                                        {
                                          item1.icon1|| <div className="function_module_table_line_check_img flex_absolute_center f18"><CheckOutlined style={{color: '#fff'}} /></div>
                                        }
                                      </div>
                                      <div className="function_module_table_line_icon2 flex_absolute_center ">
                                        {
                                          item1.icon2|| <div className="function_module_table_line_check_img flex_absolute_center f18"><CheckOutlined style={{color: '#fff'}} /></div>
                                        }
                                      </div>
                                      <div className="function_module_table_line_icon3 flex_absolute_center ">
                                        {
                                          item1.icon3|| <div className="function_module_table_line_check_img flex_absolute_center f18"><CheckOutlined style={{color: '#fff'}} /></div>
                                        }
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>

              <div className="version_selection_guide mt50 flex_column_center">
                <div className="version_selection_guide_title fb f40 color_black tc mb70">版本选择指南</div>
                {
                  this.state.versionSelectionGuideList.map((item,index)=>{
                    return(
                      <div className="version_selection_guide_item flex mb100 f24" key={index} style={{justifyContent: item.left ?'flex-start':'flex-end'}}>
                        {item.left&&<div className="version_selection_guide_item_icon mr50"><Image width={'100%'} height={'100%'} src={item.icon} preview={false} /></div>}
                        <div className={`version_selection_guide_item_content fb f24 ${item.left ?'' : 'version_selection_guide_item_content_right'}`}>{item.message}</div>
                        {!item.left&&<div className="version_selection_guide_item_icon ml50"><Image width={'100%'} height={'100%'} src={item.icon} preview={false} /></div>}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        )
    }
}