/*
 封装请求
*/

// 请求的头部
const baseURL = "/api";
// const baseURL = "http://localhost:3000/api/";
// const baseURL = "http://10.12.64.215:8500/api/";
// const baseURL = "https://www-rta-dev.round-table-union.com/api/";
// const baseServerUrl = "https://devops-dpm.round-table-union.com/api2"
const baseServerUrl = "/api2"
export const api = {
    "saveExternalLeads": {
        url: baseURL + "/bee/leads/saveExternalLeads",
        method: "post",
    },
    "fetchServerStateList": {
        url: baseServerUrl + "/deploy-manager/query-healthchek-info",
        method: "post",
    }
}