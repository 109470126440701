import React, { Component } from "react";
import ReactPlayer from 'react-player'


import Header from "../../components/header/header";
import Download from "../../components/download/download";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop/scrollTop";
import FloatToast from "../../components/float-toast/float-toast";
import customerImg from "../../static/customer/customer_success_img.png"
import customerImg2 from "../../static/customer/customer_success_img2.png"
import customerImg3 from "../../static/customer/customer_success_img3.png"
import { Image  } from 'antd';
import videoUrl0 from '../../static/customer/customer_success_video1.mp4'
import videoUrl1 from '../../static/customer/customer_success_video2.mp4'
import videoUrl2 from '../../static/customer/customer_success_video3.mp4'
import videoUrl3 from '../../static/customer/customer_success_video4.mp4'
import videoUrl4 from '../../static/customer/customer_success_video5.mp4'
import videoUrl5 from '../../static/customer/customer_success_video6.mp4'
import videoUrl6 from '../../static/customer/customer_success_video7.mp4'
import videoCover1 from '../../static/customer/customer_success_video_cover1.png'
import videoCover2 from '../../static/customer/customer_success_video_cover2.png'
import videoCover3 from '../../static/customer/customer_success_video_cover3.png'
import videoCover4 from '../../static/customer/customer_success_video_cover4.png'
import videoCover5 from '../../static/customer/customer_success_video_cover5.png'
import videoCover6 from '../../static/customer/customer_success_video_cover6.png'
import videoCover7 from '../../static/customer/customer_success_video_cover7.png'
import playIcon from '../../static/customer/video_play_icon.png'

import "./customer-success.css";
export default class CustomerSuccess extends Component {
    constructor(props){
        super(props);
        this.playerRef = React.createRef();
        this.state = {
            contents: [{
              image: customerImg,
              dialogue: '总负责人 Lily 说：',
              answer: '“自从引入了剑琅管家这款软件，多重营销玩法让我们的工作效率得到了明显提升。以前，我们手动管理预约和客户信息，经常出现错漏，现在通过软件，客户可以随时随地在线预约，系统自动分配并提醒，既方便了客户，也减轻了我们的工作负担。”',
            },{
              image: customerImg2,
              dialogue: '店长 Jack 说：',
              answer: '“剑琅管家这款软件对我们这种新手真的非常友好，即使没有技术背景的员工也能快速上手。而且，它的数据分析功能非常强大，能帮助我们深入了解客户需求和市场趋势。我们能够更加精准地制定营销策略和推广活动，吸引更多新客户并留住老客户。另外，售后服务也是没得说。”',
            },{
              image: customerImg3,
              dialogue: '经理 David 说：',
              answer: '“剑琅管家帮助我们实现了客户信息的全面数字化管理。每位顾客的档案都详细记录都在系统中，包括他们的消费历史、偏好和反馈。这不仅使我们能够更深入地了解顾客需求，还能为他们提供更加个性化的服务。此外，这款软件还提供了丰富的营销工具，如优惠券、积分兑换等，让我们能够灵活地制定营销策略，满足不同顾客的需求，真是太贴心啦！”',
            }],
            videoList:[
              {title:'新城',url:videoUrl0,coverUrl:videoCover1},
              {title:'Fei騑美甲美睫',url:videoUrl1,coverUrl:videoCover2},
              {title:'婵意美容',url:videoUrl2,coverUrl:videoCover3},
              {title:'遇见李唐',url:videoUrl3,coverUrl:videoCover4},
              {title:'媛艺美甲美睫',url:videoUrl4,coverUrl:videoCover5},
              {title:'cchair',url:videoUrl5,coverUrl:videoCover6},
              {title:'七号造型',url:videoUrl6,coverUrl:videoCover7},
            ],
            videoUrlList:[videoUrl0,videoUrl1,videoUrl2,videoUrl3,videoUrl4,],
            currentVideoIndex:0,
        }
    }
    componentDidMount() {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }
  
    componentWillUnmount() {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }
    handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        this.playerRef.current.seekTo(0); // 页面失去焦点时暂停视频
      } else {
        this.playerRef.current.seekTo(1); // 页面重新获得焦点时继续播放
      }
    };
    // 切换视频
    changeVideo(index){
      this.setState({
        currentVideoIndex:index
      })
    }

    // 自动切换下一条
    autoChangeVideo(){
      let index = this.state.currentVideoIndex;
      if(index<this.state.videoList.length-1){
        index++;
      }else{
        index=0;
      }
      this.setState({
        currentVideoIndex:index
      })
    }

    render() {
        return (
          <div className="success">
            <Header index={1} history={this.props.history}></Header>
            <FloatToast></FloatToast>
            <div className="success_audio_wrap pt10">
              <div className="success_audio flex_absolute_center">
                <ReactPlayer
                  ref={this.playerRef}
                  url={this.state.videoList[this.state.currentVideoIndex].url}
                  playing // 自动播放视频
                  width="100%"
                  height="100%"
                  controls
                  style={{ margin: 'auto' }}
                  // onEnded={this.autoChangeVideo.bind(this)}
                />
              </div>
            </div>
            <div className="success_audio_list_warp">
              {
                this.state.videoList.map((item,index)=>{
                  return (
                    <div className="success_audio_list_item tc" key={index}>
                      <div className="success_audio_list_item_video">
                        <img
                          src={item.coverUrl}
                          alt=""
                          style={{width: '100%', height: '100%'}}
                        />
                        <div className="success_audio_list_item_pop" onClick={this.changeVideo.bind(this,index)}>
                          {this.state.currentVideoIndex===index&&<div className="success_audio_play_mark"><div className="success_audio_play_mark_dot mr5"></div>正在播放</div>}
                          {this.state.currentVideoIndex!==index&&<img className="success_audio_list_item_pop_icon" src={playIcon} alt="" />}
                        </div>
                      </div>
                      <div className="success_audio_list_item_title mt20">{item.title}</div>
                    </div>
                  )
                })
              }
            </div>
            <div className="success_modules flex_column_center color_black">
              {
                this.state.contents.map((item,index)=>{
                  return (
                    <div className="success_module_item" style={{background:index%2===0?'#F8FAFF':'#FFFFFF'}} key={index}>
                      <div className="success_module_item-card flex_absolute_center">
                        <div className="success_module_item_right"><Image preview={false} src={item.image} width={'100%'} height={'100%'} /></div>
                        <div className="success_module_item_left ml50 fw400">
                          <div className="success_module_item_title  f30 flex"> 
                            <div className="success_module_item_title_icon mr20"></div>{item.dialogue}
                          </div>
                          <div className="success_module_item_desc flex f24 mt30">
                            <div className="success_module_item_title_icon mr20"></div>{item.answer}</div>
                        </div>
                      </div>
                </div>
                )
              })
            }
            </div>
            {/* <Download history={this.props.history}></Download> */}
            <div className="h20"></div>
            <Footer index={1} history={this.props.history}></Footer>
            <ScrollTop></ScrollTop>
          </div>
        )
    }
}