import React, { Suspense } from "react";

//引入需要用到的页面组件
import Home from "../pages/home";
import CustomerSuccess from "../pages/customer-success/customer-success";
import AboutMe from "../pages/about-me/about-me";
import Software from "../pages/software/software";
import Safe from "../pages/product/safe/safe";
import PartnerSettlement from "../pages/partner-settlement/partner-settlement";
// import System from "../pages/system/system";
import Product from "../pages/product/product";

import HomeMobile from "../pages/mobile/home/home";
import CustomerSuccessMobile from "../pages/mobile/customer-success/customer-success";
import ProductMobile from "../pages/mobile/product/product";
import PartnerSettlementMobile from "../pages/mobile/partner-settlement/partner-settlement";
import AboutMeMobile from "../pages/mobile/about-us/about-us";
import SoftwareMobile from "../pages/mobile/software/software";

import Login from "../pages/login/login";
import Page304 from "../pages/304/304";


import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
  } from "react-router-dom";


  function isMobile() {
    const userAgent = navigator.userAgent;
    return userAgent.match(/(iPhone|iPod|Android|ios|iPad|AppleWebKit.*Mobile.*)/i);
  }
  function router() {

    return (
      <Router>
        <Suspense fallback={<div> Loading... </div>}>
          <Switch>
            <Route path="/home" component={isMobile() ? HomeMobile : Home} />
            <Route path="/customerSuccess" component={isMobile() ? CustomerSuccessMobile : CustomerSuccess} />
            <Route path="/partnerSettlement" component={isMobile() ? PartnerSettlementMobile : PartnerSettlement} />
            <Route path="/aboutMe" component={isMobile() ? AboutMeMobile : AboutMe} />
            <Route path="/software" component={isMobile() ? SoftwareMobile : Software} />
            <Route path="/safe" component={Safe} />
            <Route path="/product" component={isMobile() ? ProductMobile : Product} />
            
            <Route path="/login" component={Login} />
            <Route path="/304" component={Page304} />
            <Route path="/" render={() => <Redirect to="/home" />}></Route>
          </Switch>
        </Suspense>
      </Router>
    );
  }
  export default router;