import React from "react";
import { message } from "antd";
import "./mobileInfoToast.css";
import { _ } from "../../common/request";
import mobileToastCloseIcon from "../../static/images/mobile_toast_close_icon.png";
import hotLineIcon from '../../static/images/hot_line_icon.png'

class MobileInfoToast extends React.Component{
  constructor(props) {
      super(props);
      this.state = {
          name:'',
          mobile:'',
          preSuccess: false
      };
  }

  componentDidMount() {
  }
  componentWillUnmount = () => {
    this.setState = (state,callback)=>{
      return;
    };
  }
  handleClick(){
    if(!this.state.name){
      return message.error("请输入您的姓名");
    }
    if(!this.state.mobile){
      return message.error("请输入您的手机号");
    }
    this.handleClickSavePhone()
  }
  
  handleClickSavePhone(){
    let num = this.state.mobile.slice(-4);
    let params = {
      leadsSource:11,
      industryType:4,
      customerIntention:1,
      customerName:'客戶'+num + this.state.name,
      shopName:'店铺'+num,
      shopRole:1,
      mobile: this.state.mobile
    }
    _.saveExternalLeads(params).then(res=>{
      if(res.code === '200'){
        this.setState({
          preSuccess: true,
        })
      }
    })
  }
  handleClose(){
    if(this.props.handleShowInfoToast !== undefined){
      this.props.handleShowInfoToast()
    }
  }
  render(){
      return (
          <div className="mobile_info_toast_wrap" onClick={(e)=>e.preventDefault()}>
            <div className="mobile_info_toast_card">
              {!this.state.preSuccess&&<div>
                <div className="mobile_info_toast_header">
                  <div className="mobile_info_toast_header_title">剑琅管家</div>
                  <div className="mobile_info_toast_header_desc ml32">简单·易用·有效果</div>
                </div>
                <div className="mobile_info_toast_content_left_item">
                  <div className="mobile_content_item_dote"></div>
                  <div className="mobile_content_item_text ml15">年交易额<p className="mobile_content_item_text_num">1000亿</p></div>
                </div>
                <div className="mobile_info_toast_content_left_item">
                  <div className="mobile_content_item_dote"></div>
                  <div className="mobile_content_item_text ml15">服务<p className="mobile_content_item_text_num">3000万</p>会员消费者</div>
                </div>
                <div className="mobile_info_toast_content_left_item">
                  <div className="mobile_content_item_dote"></div>
                  <div className="mobile_content_item_text ml15">全国<p className="mobile_content_item_text_num">2万</p>多家美业门店的选择</div>
                </div>
                <div className="mobile_info_toast_content_left_item">
                  <div className="mobile_content_item_dote"></div>
                  <div className="mobile_content_item_text ml15">为<p className="mobile_content_item_text_num">15万</p>美业手艺人提供专业服务</div>
                </div>
                <div className="mobile_info_toast_input_wrap" style={{marginTop:'0.8rem'}}><input className="mobile_info_toast_input" placeholder="请输入您的姓名" onChange={(e)=>{this.setState({name:e.target.value})}}></input></div>
                <div className="mobile_info_toast_input_wrap"><input className="mobile_info_toast_input" placeholder="请输入您的手机号码" onChange={(e)=>{this.setState({mobile:e.target.value})}}></input></div>
                <div className="mobile_info_toast_submit_btn" onClick={this.handleClick.bind(this)}>确认</div>
              </div>}
              
              {this.state.preSuccess&&<div className="flex_column_center">
                <div className="mobile_info_toast_success_title">预约成功</div>
                <div className="mobile_info_toast_success_tip">专家顾问将在10分钟内向您致电</div>
                <div className="mobile_info_toast_success_desc">非工作时间可能会延迟，敬请谅解</div>
                <div className="mobile_info_toast_content_number_warp flex_column_center">
                  <img className="mobile_info_toast_header_icon" src={hotLineIcon} alt=""></img>
                  <div className="mobile_info_toast_content_title">剑琅联盟全国热线电话：</div>
                  <div className="mobile_info_toast_content_number">021-6331 6098</div>
                </div>
              </div>}


              <img className="mobile_toast_close_icon" src={mobileToastCloseIcon} alt=""  onClick={this.handleClose.bind(this)}></img>
            </div>
          </div>
        )
  }
}

export default MobileInfoToast;