import Router from './router/router';
import "./static/css/initial-style.css";
import "./static/css/base.css";
import "./static/css/mixin.css";
import "antd/dist/reset.css";
import "./static/js/global";

function App() {
  return (
    <Router/>
  );
}

export default App;
