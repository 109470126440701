import React from "react";
import "./style.css";
import { DownOutlined } from '@ant-design/icons';
import officialLogo from '../../static/images/official_logo.png'
import dragonMomIcon from '../../static/images/dragon_mom_icon.png'
import productTabListIcon2 from '../../static/images/product_tab_list_icon2.png'
import productTabListIcon3 from '../../static/images/product_tab_list_icon3.png'
import productTabListIcon4 from '../../static/images/product_tab_list_icon4.png'
 
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_top: false, //是否显示顶部白色
      name: "剑琅联盟",
      callName: "定价",
      callButton: "免费试用",
      chooseIndex: 0,
      currentTabsInIndex:0,
      menu: [{
        index: 0,
        name: "首页",
      },{
        index: 1,
        name: "客户成功故事",
        showList: false,
      },{
        index: 2,
        name: "产品与服务",
        showList: false,
        children: [{
            index: 0,
            name: "剑琅管家",
            icon: officialLogo,
          },{
            index: 1,
            name: "医美医务系统",
            icon: productTabListIcon2
          },{
            index: 2,
            name: "安全保障与护航",
            icon: productTabListIcon3
          }
          // ,{
          //   index: 3,
          //   name: "代运营服务",
          //   icon: productTabListIcon4
          // }
        ]
      },{
        index: 3,
        name: "合作伙伴入驻",
        showList: false,
      },{
        index: 4,
        name: "关于我们",
        showList: false,
      },{
        index: 5,
        name: "软件下载",
        showList: false,
      }]
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.setState({
      chooseIndex: this.props.index,
      currentTabsInIndex:localStorage.getItem('currentTabsInIndex')*1 || 0
    })
  }

  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
    window.removeEventListener('scroll', this.handleScroll);
    localStorage.removeItem('fromOtherPage')
  }
  
  // 监听滚动条事件
  handleScroll=()=>{   
    this.leaveTab()
  }

  // 去登录页面
  goLogin() {
    window.open('https://webrtb.round-table-union.com');
  }

  hoverTab(index) {
    let array = this.state.menu;
    Array.from(array).forEach((item, i) => {
      if (i === index) {
        item.showList = true;
      } else {
        item.showList = false;
      }
    });
    this.setState({
      menu: array
    });
  }

  leaveTab(){
    let array = this.state.menu;
    Array.from(array).forEach((item, i) => {
      item.showList = false;
    });
    this.setState({
      menu: array
    });
  }

  chooseTab(index){
    if(index === 0) {
      this.props.history.push({ pathname: "/home" });
    }  else if(index === 1) {
      this.props.history.push({ pathname: "/customerSuccess" });
    }  else if(index === 2) {
      this.props.history.push({ pathname: "/product"});
    } else if(index === 3) {
      this.props.history.push({ pathname: "/partnerSettlement" });
    } else if(index === 4) {
      this.props.history.push({ pathname: "/aboutMe" });
    }  else if(index === 5) {
      this.props.history.push({ pathname: "/software" });
    }  else if(index === 6) {
      this.props.history.push({ pathname: "/safe" });
    }
    window.scrollTo({
      top: 0, 
      behavior: 'auto',
    });
  }
  clickTab(index){
    if(this.props.handleChangeTab !== undefined){
      this.props.handleChangeTab(index);
    }
    this.setState({currentTabsInIndex:index})
    localStorage.setItem('currentTabsInIndex',index)
    this.leaveTab()
  }

  render() {    
    let { chooseIndex, name, menu, callButton,currentTabsInIndex } = this.state;
    return (
      <div className="header-box">
          <div className="flex header-left">
            <img alt="LOGO" className="logo" src={officialLogo}></img>
            <p className="name">{name}</p>
            <div className="flex">
            {
              menu.map((item, index) => {
                return (
                  <div key={index} className="flex header-tab" onMouseEnter={this.hoverTab.bind(this, index)} onClick={this.chooseTab.bind(this, index)}>
                    <p className={`header-title ${chooseIndex === index ?'header-title-choose':''}`}>{item.name}</p>
                     {item.children ? <DownOutlined className={`down ${item.showList?'down_active':''}`} style={{color:chooseIndex === index ?'#206EF7':'#333333'}} />:''}
                    <p className={`line ${chooseIndex === index ?'':'bg'}`}></p>
                    {item.children &&item.showList&& <div className="header_drop_list"  onMouseLeave={this.leaveTab.bind(this)}>
                      {
                        item.children.map((item1,index1)=>{
                          return <div className="header_drop_list_item flex f24 fw400" style={{color: currentTabsInIndex===index1?'#206EF7':''}} key={index1} onClick={this.clickTab.bind(this,item1.index)}>
                            <div className="header_drop_list_item_box ml34 mr20"><img className="header_drop_list_item_icon" src={item1.icon} alt="" /></div>
                            {item1.name}
                          </div>
                        })
                      }
                    </div>}
                  </div>
                )
              })
            }
            </div>
          </div>
          <div className="flex header-right ml160">
            <div className="header-dragon" onClick={this.chooseTab.bind(this,6)}>
              <img alt="龙妈护航" className="call-icon" src={dragonMomIcon}></img>
              <p className="call">龙妈护航</p>
            </div>
            <div className="button" onClick={() => {this.goLogin()}}>{callButton}</div>
          </div>
      </div>
    );
  }
}

export default Header;
